<template>
  <label
    v-if="label"
    :class="{ required }"
    class="form-label">
    <span>{{ label }}</span>
    <b v-if="required" class="ml-1 blue">*</b>
  </label>
</template>
<script>

export default {
  name: 'CoreLabel',
  props: {
    label: {},
    required: {}
  }
}
</script>
