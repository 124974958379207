<template>
  <div class="container-fluid">
    <Card title="Pedidos" subtitle="Lista de pedidos com menu de ações">
      <FilterBar>
        <FilterBox label="Venda efetuadas em">
          <DateTimePicker
            v-model="search.created_at_i"
            :with-time="false"
            placeholder="Inicial"
          />

          <DateTimePicker
            v-model="search.created_at_f"
            :with-time="false"
            placeholder="Final"
          />
        </FilterBox>
      </FilterBar>

      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <ContextMenuHeader/>
            <th data-align="center" data-column-id="ticket" data-formatter="ticket" data-width="36px"></th>
            <th data-column-id="description">Descrição</th>
            <th data-column-id="customer_id" data-visible="false">Cliente ID</th>
            <th data-column-id="customer.name">Cliente</th>
            <th data-column-id="seller_id" data-visible="false">Vendedor ID</th>
            <th data-column-id="status_display_text">Status</th>
            <th data-column-id="gateway">Pagamento</th>
            <th data-column-id="fee_amount" data-formatter="number" data-align="right" data-width="150px">Taxa</th>
            <th data-column-id="total" data-formatter="number" data-align="right" data-width="150px">Total</th>
            <th data-column-id="created_at" data-formatter="dt" data-width="150px">Hora</th>
          </tr>
        </thead>
      </table>
    </Card>

    <OrderAccessDialog ref="OrderAccessDialog"/>
  </div>
</template>

<script>
import $ from 'jquery'
import { H, initGrid } from 'hennig-common'
import { showInfo } from '@/notifications'
import PkHeader from '@/components/grid/PkHeader'
import OrderAccessDialog from '../dialogs/OrderAccessDialog'
import DateTimePicker from '@/components/DateTimePicker'
import Card from '@/components/Card'
import ContextMenuHeader from '@/components/grid/ContextMenuHeader.vue'
import FilterBar from '@/components/FilterBar.vue'
import FilterBox from '@/components/FilterBox.vue'

export default {
  name: 'Orders',
  components: {
    FilterBox,
    FilterBar,
    ContextMenuHeader,
    Card,
    DateTimePicker,
    OrderAccessDialog,
    PkHeader
  },
  data () {
    return {
      search: {
        created_at_i: '',
        created_at_f: ''
      }
    }
  },
  async mounted () {
    await this.$nextTick()
    this.init()
  },
  methods: {
    init () {
      const that = this
      const collectionObj = 'Order'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        search: () => this.search,
        noAddButton: true,
        customMethods: {
          cancelOrder (_id, $that, $grid) {
            const text = 'Deseja cancelar este pedido?'
            const dialog = that.dialogs().Confirmation
            dialog.show({ text }, r => {
              if (r) {
                H.rpc('Order', 'cancel', [_id], r => {
                  if (r) {
                    showInfo('Este pedido foi cancelado')
                    $grid.bootgrid('reload')
                  }
                })
              }
            })
          },
          setPaid (_id, $that, $grid) {
            const text = 'Deseja marcar este pedido como pago?'
            const dialog = that.dialogs().Confirmation
            dialog.show({ text }, r => {
              if (r) {
                H.rpc('Order', 'setPaid', [_id], r => {
                  if (r) {
                    showInfo('Este pedido foi pago manualmente')
                    $grid.bootgrid('reload')
                  }
                })
              }
            })
          }
        },
        formatters: {
          mnu (column, row) {
            const items = `
                <div class="dropdown-item">
                  <i class="la la-undo command cancelOrder"></i>
                  <span class="command cancelOrder" data-row-id="${row._id}">Cancelar esta venda</span>
                </div>

                <div class="dropdown-item">
                  <i class="la la-money command setPaid"></i>
                  <span class="command setPaid" data-row-id="${row._id}">Marcar este pedido como pago</span>
                </div>
`
            return `
              <div class="dropdown position-absolute">
                <i class="la la-ellipsis-v link" data-toggle="dropdown" aria-expanded="false"></i>
                <div class="dropdown-menu">
                  ${items}
                </div>
              </div>`
          }
        },
        actions: [
          {
            icon: 'la-ticket-alt',
            name: 'ticket',
            handler (_id, $that, $grid) {
              // Visualizar tickets
              that.$refs.OrderAccessDialog.show({ order_id: _id })
            }
          }
        ]
      }).on('loaded.rs.jquery.bootgrid', function (evnt) {
        $('.la-ellipsis-v.link').dropdown()
      })
    }
  }
}
</script>
