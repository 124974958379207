<template>
  <div id="app">
    <router-view name="header" class="mb-2"></router-view>
    <div>
      <router-view :key="$route.fullPath"></router-view>
      <notifications position="top center" group="main"></notifications>
      <dialogs ref="Dialogs"></dialogs>
    </div>
  </div>
</template>

<style lang="scss">
@import "sass/app";

#app {}
</style>

<script>

import Dialogs from '@/dialogs/Dialogs'

export default {
  components: { Dialogs },
  mounted () {
    document.querySelector('.wait').remove()
  }
}
</script>
