<template>
  <div v-if="data.event" class="data">
    <label>Evento</label>
    <div class="main">
      <h5>{{ data.event.name }}</h5>
      <div class="flex-v-center">
        <label class="mr-3">Data: </label><b>{{ formatDateTime(data.event.web_date) }}</b>
      </div>
      <!--              <div v-if="data.event.title">{{ data.event.title }}</div>-->
    </div>
    <div class="commands">
      <EditButton/>
    </div>
  </div>
</template>

<script>
import EditButton from '@/components/EditButton'
import { formatDateTime } from '@/common'
export default {
  name: 'QueryRowEvent',
  components: { EditButton },
  props: {
    data: {}
  },
  methods: {
    formatDateTime
  }
}
</script>
