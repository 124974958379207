import Vue from 'vue'
import RulesEditor from './components/RulesEditor'
import EventSelect from './components/EventSelect'
import AccessListSelect from './components/AccessListSelect'
import CustomerSelect from './components/CustomerSelect'
import WhiteLabelSelect from './components/WhiteLabelSelect'
import { MultiSelectEditor } from 'hennig-common'
import GeoCoordsEditor from './components/GeoCoordsEditor'
import UploadSingleEditor from './components/UploadSingleEditor'
import ImageEditor from './components/ImageEditor'
import SectorSelect from './components/SectorSelect'
import HtmlEditor from './components/HtmlEditor'
import EventGroupMultiselect from './components/EventGroupMultiselect'
import FingerPrintsEditor from '@/components/FingerPrintsEditor'
import HtmlEditorVariables from '@/components/HtmlEditorVariables.vue'
import TextAreaEditorVariables from '@/components/TextAreaEditorVariables.vue'

Vue.component('RulesEditor', RulesEditor)
Vue.component('EventSelect', EventSelect)
Vue.component('SectorSelect', SectorSelect)
Vue.component('AccessListSelect', AccessListSelect)
Vue.component('CustomerSelect', CustomerSelect)
Vue.component('WhiteLabelSelect', WhiteLabelSelect)
Vue.component('MultiSelectEditor', MultiSelectEditor)
Vue.component('GeoCoordsEditor', GeoCoordsEditor)
Vue.component('UploadSingleEditor', UploadSingleEditor)
Vue.component('ImageEditor', ImageEditor)
Vue.component('FingerPrintsEditor', FingerPrintsEditor)
Vue.component('HtmlEditor', HtmlEditor)
Vue.component('HtmlEditorVariables', HtmlEditorVariables)
Vue.component('TextAreaEditorVariables', TextAreaEditorVariables)
Vue.component('EventGroupMultiselect', EventGroupMultiselect)
