<template>
  <div class="container-xl">
    <Card :title="title" :subtitle="subtitle">
      <FilterBar>
        <FilterBox v-if="event_visible">
          <label>Evento</label>
          <EventSelect
            init-placeholder="Selecione um evento"
            v-model="search.event_id"
          />
        </FilterBox>
        <FilterBox>
          <label>Lista</label>
          <AccessListSelect
            :disabled="!search.event_id"
            :form-data="{ event_id: search.event_id }"
            init-placeholder="Selecione uma lista"
            v-model="search.accesslist_id"
          />
        </FilterBox>
      </FilterBar>

      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <EditHeader/>
            <DeleteHeader/>
            <StatusHeader/>
            <ContextMenuHeader/>

            <th data-column-id="event_id" data-visible="false">Evento ID</th>
            <th v-if="event_visible" data-column-id="event.name">Evento</th>
            <th data-column-id="sector.name">Setor</th>
            <th data-column-id="category_id" data-visible="false">Categoria ID</th>
            <th data-column-id="category.name">Categoria</th>
            <th data-column-id="name" data-sortable="true">Produto</th>
            <th data-column-id="price" data-sortable="true" data-formatter="number" data-align="right">Preço R$</th>

            <th data-column-id="col-progress" data-css-class="col-progress" data-width="36px" data-header-no-icon="true"></th>

            <th
              data-align="center"
              data-column-id="access_link"
              data-formatter="access_link"
              data-width="36px"
              data-header-no-icon="true"
            ></th>
          </tr>
        </thead>
      </table>

    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { H, initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import Card from '@/components/Card'
import { Events } from '@/events'
import ContextMenuHeader from '@/components/grid/ContextMenuHeader.vue'
import { showInfo } from '@/notifications'
import FilterBar from '@/components/FilterBar.vue'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'
import FilterBox from '@/components/FilterBox.vue'

export default {
  name: 'Products',
  components: {
    FilterBox,
    FilterBar,
    ContextMenuHeader,
    Card,
    StatusHeader,
    DeleteHeader,
    EditHeader,
    PkHeader
  },
  data () {
    return {
      event_visible: true,
      search: {
        event_id: ''
      }
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id', 'event_name']),
    title () {
      return 'Produtos'
      // if (this.event_visible) {
      // }
      //
      // return `Produtos (${this.event_name})`
    },
    subtitle () {
      if (this.event_visible) {
        return 'Listagem de produtos com link para os ingressos'
      }

      return `Listagem de produtos com link para os ingressos (${this.event_name})`
    }
  },
  async mounted () {
    // Navegacao veio de uma listagem que setou filtro em querystring
    if (this.$route.query.event_id) {
      this.search.event_id = this.$route.query.event_id
    } else {
      // Se tem um filtro setado no Pinia
      if (this.event_id) {
        this.search.event_id = this.event_id
        // Nao precisa mostrar a coluna
        this.event_visible = false
      }
    }

    Events.$on('UI.Progress', data => {
      const $tr = $(`tr[data-row-id="${data._id}"]`)
      $tr.find('.col-progress').text(data.value)
    })

    await this.$nextTick()
    this.init()
  },
  methods: {
    init () {
      const that = this
      const addLabel = 'Novo produto'
      const collectionObj = 'Product'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        modalAdd: false,
        customAdd: (collectionObj, $grid) => {
          H.rpc(collectionObj, 'form', [], (r) => {
            if (!r) return
            r.data.event_id = that.event_id
            H.createForm(r, {
              onstore (evnt, data, $form) {
                $grid.bootgrid('reload')
                $form.closest('.modal').modal('hide')
              }
            })
          })
        },
        search: () => this.search,
        addLabel,
        actions: [
          {
            icon: 'la-angle-right',
            name: 'access_link',
            handler (_id, $that, $grid, getRow) {
              that.$router.push({
                path: '/access',
                query: {
                  product_id: _id
                }
              })
            }
          }
        ],
        customMethods: {
          generateAccessCharge (_id) {
            that.dialogs().GenerateAccessChargeDialog.show(response => {
              if (!response) return
              H.rpc('Product', 'generateAccessCharge', [_id, response.qty], r => {
                if (r === 'ok') {
                  showInfo('Executando em segundo plano')
                }
              })
            })
          },
          duplicateProduct (_id) {
            that.dialogs().Confirmation.show(response => {
              if (!response) return
              H.rpc('Product', 'duplicate', [_id], r => {
                if (r) {
                  showInfo('O produto foi duplicado')
                }
              })
            })
          }
        },
        formatters: {
          mnu (column, row) {
            const items = `
                <div class="dropdown-item">
                  <i class="la la-folder-plus command generateAccessCharge"></i>
                  <span class="command generateAccessCharge" data-row-id="${row._id}">Gerar uma carga de ingressos</span>
                </div>

                <div class="dropdown-item">
                  <i class="la la-copy command duplicateProduct"></i>
                  <span class="command duplicateProduct" data-row-id="${row._id}">Duplicar este produto</span>
                </div>
`
            return `
              <div class="dropdown position-absolute">
                <i class="la la-ellipsis-v link" data-toggle="dropdown" aria-expanded="false"></i>
                <div class="dropdown-menu">
                  ${items}
                </div>
              </div>`
          }
        }
      }).on('loaded.rs.jquery.bootgrid', function (evnt) {
        $('.la-ellipsis-v.link').dropdown()
      })
    }
  }
}
</script>
