<template>
  <div :class="getClass" class="CoreInput">
    <CoreLabel
      :label="label"
      :label-tooltip="labelTooltip"
      :required="required"
    />

    <div class="input-group">
      <slot name="prepend">

      </slot>

      <input
        :class="getClassInput"
        :disabled="disabled"
        :placeholder="getPlaceholder"
        :required="required"
        :value="modelValue"
        class="form-control"
        type="text"
        v-bind="$attrs"
        @blur="validate(modelValue); $emit('blur', $event)"
        @input="onFieldInput"
        @keyup="showSuggestions($event); $emit('keyup', $event)"
      />

      <slot name="append">

      </slot>
    </div>

    <div
      v-if="showingSuggestions && suggestions !== null"
      class="list-group w-100 position-absolute shadow"
      style="z-index: 10;"
    >
      <div
        v-if="loadingSuggestions"
        class="list-group-item list-group-item-action disabled"
      >
        Procurando...
      </div>
      <template v-else-if="suggestionsRows.length">
        <button
          v-for="(row, key) in suggestionsRows"
          :key="`suggestion${key}`"
          class="list-group-item list-group-item-action"
          @click.stop="emitClickSuggestion(row)"
        >
          {{ row.name }}
        </button>
      </template>
      <div
        v-else
        class="list-group-item list-group-item-action disabled"
      >
        Nenhum resultado
      </div>
    </div>

    <div class="invalid-feedback" v-html="customFeedback || feedback"></div>
  </div>
</template>

<script>
import CoreLabel from '@/components/CoreLabel'

export default {
  name: 'CoreInput',
  components: {
    CoreLabel
  },
  inheritAttrs: false,
  props: {
    modelValue: {},
    label: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    customFeedback: {
      type: String,
      default: ''
    },
    labelTooltip: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    suggestions: {
      type: Function,
      default: null
    }
  },
  emits: [
    'update:modelValue',
    'update:customFeedback',
    'click:suggestion',
    'isValid'
  ],
  data () {
    return {
      wasValidated: false,
      showingSuggestions: false,
      loadingSuggestions: false,
      suggestionsRows: [],
      feedback: ''
    }
  },
  computed: {
    getPlaceholder () {
      if (this.loading) {
        return ''
      }

      return this.placeholder
    },
    getClass () {
      return {
        'placeholder-glow': this.loading,
        disabled: this.disabled
      }
    },
    getClassInput () {
      const response = {
        placeholder: this.loading
      }
      for (const c of this.inputClass.split(/\s+/)) {
        response[c] = true
      }

      if (this.wasValidated) {
        if (this.feedback || this.customFeedback) {
          response['is-invalid'] = true
        } else {
          response['is-valid'] = true
        }
      }

      return response
    }
  },
  watch: {
    /**
     * When the value is restored w/o user typing in field
     *
     * @param n
     * @param o
     */
    modelValue (n, o) {
      if (n !== o) {
        this.validate(n)
        if (!n) this.wasValidated = false
      }
    }
  },
  methods: {
    async showSuggestions (evnt) {
      if (!this.suggestions) return
      this.showingSuggestions = true
      this.loadingSuggestions = true
      this.suggestionsRows = await this.suggestions(evnt.target.value)
      this.loadingSuggestions = false
    },
    fieldInput (v) {
      this.validate(v)
      this.$emit('update:modelValue', v)
      // Clean customFeedback requires .sync
      this.$emit('update:customFeedback', '')
    },
    onFieldInput (e) {
      this.fieldInput(e.target.value)
    },
    emitClickSuggestion (row) {
      this.showingSuggestions = false
      this.fieldInput(row.text || '')
      this.$emit('click:suggestion', row)
    },
    emitIsValid () {
      this.$emit('isValid', this.wasValidated && !this.feedback)
    },
    validate (v) {
      if (!this.rules.length) return
      this.wasValidated = true
      for (const rule of this.rules) {
        try {
          rule(v)
          this.feedback = ''
        } catch (e) {
          this.feedback = e.message
          break
        }
      }
      this.emitIsValid()
    }
  }
}
</script>

<style lang="scss" scoped>
.CoreInput {
  position: relative;

  &.disabled {
    .form-control,
    .form-label {
      opacity: 0.5;
    }
  }

  .form-control {
    padding-right: 42px;
    &::placeholder {
      font-size: .6em;
    }
  }
}
</style>
