<template>
  <BaseModal
    ref="BaseModal"
    title="Gerar carga de ingressos"
    v-if="visible"
    size="modal-sm"
  >
    <form onsubmit="return false" autocomplete="off">
      <div class="d-flex">
        <CoreInput
          label="Quantidade"
          :model-value.sync="form.qty"
        />
      </div>
    </form>
    <template v-slot:footer>
      <button
        class="btn btn-outline-primary"
        @click="secondary()"
      >Cancelar
      </button>
      <button
        class="btn btn-primary"
        @click="primary()"
      >Gerar
      </button>
    </template>
  </BaseModal>
</template>

<script>
import { BaseModal } from 'hennig-common'
import CoreInput from '@/components/CoreInput.vue'

export default {
  name: 'GenerateAccessChargeDialog',
  components: {
    CoreInput,
    BaseModal
  },
  data () {
    return {
      visible: false,
      form: {
        qty: 100
      }
    }
  },
  methods: {
    show (callback) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.BaseModal.show()
        this.callback = callback
      })
    },
    hide () {
      this.$refs.BaseModal.hide()
      this.visible = false
    },
    primary () {
      this.callback && this.callback(this.form)
      this.hide()
    },
    secondary () {
      this.callback && this.callback(false)
      this.hide()
    }
  }
}
</script>
