<template>
  <VueSelect
    v-model="current"
    :filterable="false"
    :disabled="disabled"
    :options="options"
    :placeholder="placeholder"
    :reduce="row => row._id"
    label="trade_name"
    @input="emitInput"
    @search="fetchOptions"
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">
        Nenhum white label encontrado: <em>{{ search }}</em>.
      </template>
      <em v-else style="opacity: 0.5;">Digite para procurar...</em>
    </template>
  </VueSelect>
</template>

<script>
import { VueSelect } from 'vue-select'
import { H, BaseComponent } from 'hennig-common'

export default {
  name: 'WhiteLabelSelect',
  components: { VueSelect },
  extends: BaseComponent,
  props: {
    init_placeholder: { String, default: '' },
    disabled: { Boolean, default: false }
  },
  data () {
    return {
      placeholder: ''
    }
  },
  mounted () {
    this.baseMounted()
  },
  methods: {
    setValue (v) {
      this.fetchOptions(v, (loading) => {
        if (loading === false) {
          this.current = v
          this.emitInput(v)
        }
      })
    },
    fetchOptions (search, loading) {
      search = search || this.current || ''

      loading && loading(true)
      this.options = []
      H.rpc('Customer', 'lookup', [search], r => {
        if (r) {
          this.options = r
        }

        loading && loading(false)
      })
    }
  }
}
</script>
