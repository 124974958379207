<template>
  <div>
    <Confirmation ref="Confirmation"/>
    <Upload ref="Upload"/>
    <Password ref="Password"/>
    <ControlIdInit ref="ControlIdInit"/>
    <DeviceTokenDialog ref="DeviceTokenDialog"/>
    <MonthDialog ref="MonthDialog"/>
    <DayDialog ref="DayDialog"/>
    <GenerateAccessChargeDialog ref="GenerateAccessChargeDialog"/>
  </div>
</template>

<script>
import Confirmation from './Confirmation'
import Upload from './Upload'
import Password from './Password'
import ControlIdInit from './ControlIdInit'
import DeviceTokenDialog from './DeviceTokenDialog'
import MonthDialog from '@/dialogs/MonthDialog'
import DayDialog from '@/dialogs/DayDialog'
import GenerateAccessChargeDialog from '@/dialogs/GenerateAccessChargeDialog.vue'

export default {
  name: 'Dialogs',
  components: {
    GenerateAccessChargeDialog,
    DayDialog,
    MonthDialog,
    DeviceTokenDialog,
    ControlIdInit,
    Password,
    Upload,
    Confirmation
  }
}
</script>
