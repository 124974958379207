import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebaseui/dist/firebaseui.css'
import * as firebaseui from 'firebaseui'

const firebaseConfig = {
  apiKey: 'AIzaSyBmVEzm56GRrvsAWvTMSq_rrmPlKJRKbK0',
  authDomain: 'total-control-310016.firebaseapp.com',
  projectId: 'total-control-310016',
  storageBucket: 'total-control-310016.appspot.com',
  messagingSenderId: '1046625954400',
  appId: '1:1046625954400:web:4df39275c402038785f105',
  measurementId: 'G-3K27M9NB7N'
}

let backendCall
let onUser
let ui

const firebaseInit = function (uiConfig, custom = {}, element_id = '#firebaseui-auth-container') {
  if (!ui) {
    firebase.initializeApp(firebaseConfig)
    firebase.analytics()
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        onUser({
          name: user.displayName + '',
          // name: (user.displayName + '').split(/\s+/).shift(),
          email: user.email
        })
        // window.g_user._id = user.uid
        // window.g_user.name = (user.displayName + '').split(/\s+/).shift()
        // window.g_user.email = user.email
        // sessionStorage.setItem('auth_user', JSON.stringify(window.g_user))
        // User is signed in.
        // var displayName = user.displayName;
        // var email = user.email;
        // var emailVerified = user.emailVerified;
        // var photoURL = user.photoURL;
        // var uid = user.uid;
        // var phoneNumber = user.phoneNumber;
        // var providerData = user.providerData;
        // user.getIdToken().then(function (accessToken) {
        //   document.getElementById('sign-in-status').textContent = 'Signed in'
        //   document.getElementById('sign-in').textContent = 'Sign out'
        //   document.getElementById('account-details').textContent = JSON.stringify({
        //     displayName: displayName,
        //     email: email,
        //     emailVerified: emailVerified,
        //     phoneNumber: phoneNumber,
        //     photoURL: photoURL,
        //     uid: uid,
        //     accessToken: accessToken,
        //     providerData: providerData
        //   }, null, '  ')
        // })
        user.getIdToken().then(token => {
          // Envia o token firebase para o sistema
          backendCall(token)
        })

        return
      }

      onUser(null)

      // window.g_user._id = ''
      // window.g_user.name = ''
      // window.g_user.email = ''
      // sessionStorage.setItem('gates', '')
      // sessionStorage.setItem('auth_token', '')
      // sessionStorage.setItem('auth_user', '')
      // gates_loading.value = false
    })

    // Initialize the FirebaseUI Widget using Firebase.
    ui = new firebaseui.auth.AuthUI(firebase.auth())
    ui.disableAutoSignIn()

    onUser = custom.onUser || function () {
      // console.error('onUser is required')
    }
    backendCall = custom.backendCall || function () {
      console.error('backendCall is required')
    }
    uiConfig = {
      ...uiConfig,
      signInFlow: 'popup',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // 'microsoft.com'
      ]
    }
  }
  // The start method will wait until the DOM is loaded.
  ui.start(element_id, uiConfig)
}

export const firebaseLogout = () => {
  if (firebase) {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
    firebase.auth().signOut()
    onUser && onUser(null)
  }
}

export { firebaseInit }
