<template>
  <div class="container-fluid">
    <Card title="Permissões">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <EditHeader/>
            <th
              data-column-id="description"
              data-order="asc"
              data-sortable="true"
            >Descrição
            </th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { initGrid } from 'hennig-common'
import EditHeader from '@/components/grid/EditHeader'
import PkHeader from '@/components/grid/PkHeader'
import Card from '@/components/Card'

export default {
  name: 'Permissions',
  components: { Card, PkHeader, EditHeader },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const collectionObj = 'Permission'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        search: () => this.search,
        noAddButton: true
      })
    }
  }
}
</script>
