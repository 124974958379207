<template>
  <div class="container-xl">
    <Card title="Eventos">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <EditHeader/>
            <DeleteHeader/>
            <StatusHeader/>
            <ContextMenuHeader/>

            <th data-column-id="name">Nome</th>
            <th data-column-id="access_ok" data-formatter="bool" data-width="80px" data-align="center">Acesso ativo</th>
            <th data-column-id="web_ok" data-formatter="bool" data-width="80px" data-align="center">Visivel WEB</th>
            <th data-column-id="pos_ok" data-formatter="bool" data-width="80px" data-align="center">Visivel POS</th>
            <th data-column-id="web_sold_out" data-formatter="bool" data-width="80px" data-align="center">Esgotado</th>

            <th
              data-align="center"
              data-column-id="product_link"
              data-formatter="product_link"
              data-width="36px"
              data-header-no-icon="true"
            ></th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { H, initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import Card from '@/components/Card'
import { showInfo } from '@/notifications'
import ContextMenuHeader from '@/components/grid/ContextMenuHeader.vue'

export default {
  name: 'Events',
  components: {
    ContextMenuHeader,
    Card,
    StatusHeader,
    DeleteHeader,
    EditHeader,
    PkHeader
  },
  mounted () {
    this.init()
  },
  methods: {
    exportAccess (_id) {
      const that = this
      that.dialogs().Confirmation.show({
        text: 'Um arquivo com os códigos do evento será enviado para o seu e-mail. Deseja continuar?'
      }, data => {
        if (!data) return
        H.rpc('Event', 'exportAccess', [_id], r => {
          if (!r) return
          showInfo('A exportação será executada em segundo plano')
          that.dialogs().Confirmation.hide()
        })
      })
    },
    init () {
      const that = this
      const addLabel = 'Novo'
      const collectionObj = 'Event'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        modalAdd: true,
        modalEdit: true,
        addLabel,
        customMethods: {
          duplicateEvent (_id, $that, $grid) {
            that.dialogs().Confirmation.show(response => {
              if (!response) return
              H.rpc('Event', 'duplicate', [_id], r => {
                if (r) {
                  showInfo('O evento foi duplicado')
                  $grid.bootgrid('reload')
                }
              })
            })
          },
          exportAccess (_id, $that, $grid) {
            return that.exportAccess(_id)
          }
        },
        actions: [
          {
            icon: 'la-angle-right',
            name: 'product_link',
            handler (_id, $that, $grid, getRow) {
              that.$router.push({
                path: '/products',
                query: {
                  event_id: _id
                }
              })
            }
          }
        ],
        formatters: {
          mnu (column, row) {
            const items = `
                <div class="dropdown-item">
                  <i class="la la-copy command duplicateProduct"></i>
                  <span class="command duplicateEvent" data-row-id="${row._id}">Duplicar este evento</span>
                </div>

                <div class="dropdown-item">
                  <i class="la la-download command exportAccess"></i>
                  <span class="command exportAccess" data-row-id="${row._id}">Exportar códigos deste evento</span>
                </div>
`
            return `
              <div class="dropdown position-absolute">
                <i class="la la-ellipsis-v link" data-toggle="dropdown" aria-expanded="false"></i>
                <div class="dropdown-menu">
                  ${items}
                </div>
              </div>`
          }
        }
      }).on('loaded.rs.jquery.bootgrid', function (evnt) {
        $('.la-ellipsis-v.link').dropdown()
      })
    }
  }
}
</script>
