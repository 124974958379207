<template>
  <div class="TicketVariables">
    <div class="text-primary">Váriaveis disponíveis</div>
    <div class="variables-container">
      <div @click="addVar('event_name')">Nome do evento</div>
      <div @click="addVar('event_date')">Data do evento</div>
      <div @click="addVar('event_local')">Local do evento</div>
      <div @click="addVar('product_name')">Produto</div>
      <div @click="addVar('product_sector_name')">Setor</div>
      <div @click="addVar('product_category_name')">Categoria</div>
      <div @click="addVar('accesslist_details')">Instruções de acesso</div>
      <div @click="addVar('code')">QR Code</div>
      <div @click="addVar('seq')">Sequencial</div>
      <div @click="addVar('type')">Tipo (Carga ou Ingresso)</div>
      <div @click="addVar('product_type')">Tipo de produto (Normal, Meia ou Cortesia)</div>
      <div @click="addVar('price')">Preço (R$ 0,00)</div>
      <div @click="addVar('order_at')">Data do pedido</div>
      <div @click="addVar('order_code')">Localizador do pedido</div>
      <div @click="addVar('user_name')">Cliente</div>
      <div @click="addVar('user_cpf')">CPF</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TicketVariables',
  methods: {
    addVar (name) {
      this.$emit('selected', name)
    }
  }
}
</script>

<style scoped lang="scss">
.TicketVariables {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .variables-container {
    min-width: 200px;
    border: 1px solid var(--border-color);

    > div {
      cursor: pointer;
      padding: .25rem .5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }
}
</style>
