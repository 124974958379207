<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'QueryBase',
  methods: {}
}
</script>

<style lang="scss" scoped>
::v-deep {
  .flex-h {
    display: flex;
    justify-content: left;
    gap: 1rem;
  }

  .flex-v-center {
    display: flex;
    align-items: center;
    gap: .25rem;
  }

  .data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: .5rem;

    > label {
      width: 200px;
    }

    > .main {
      flex-grow: 1;
    }

    > .commands {
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }
  }

  label {
    margin: 0;
    display: inline-block;
    font-size: .8rem;
    color: silver;
  }
}
</style>
