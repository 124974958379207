<template>
  <div class="container">
    <Card title="Meus dados">
      <div class="default-mb">
        <label>Nome</label>
        <input v-model="form.name" class="form-control"/>
      </div>
      <div class="default-mb">
        <label>CPF</label>
        <input v-model="form.cpf" class="form-control"/>
      </div>
      <template #buttons>
        <div class="card-footer text-right">
          <CoreButton
            :loading="formSaving"
            :disabled="formSaving"
            @click="save()">Salvar
          </CoreButton>
        </div>
      </template>
    </Card>
    <Card subtitle="Geração de token para acesso API, pode ser informado tempo de vida deste token" title="API">
      <div class="d-flex align-items-end default-mb">
        <CoreSelect
          v-model="api.expire"
          :items="expire_items"
          class="flex-grow-1 default-mr"
          label="Expirar"
        />

        <div class="default-mr">
          <label>Senha criptografia</label>
          <input v-model="api.decrypt_key" class="form-control"/>
        </div>

        <CoreButton @click="token()">Gerar token</CoreButton>
      </div>
      <div class="default-mb">
        <CoreLabel label="Documentação online"/>
        <a href="https://acesso.center/api">https://acesso.center/api</a>
      </div>
      <div>
        <label>Token</label>
        <textarea v-model="api.token" class="form-control text-monospace" readonly/>
      </div>
    </Card>
  </div>
</template>

<script>
// todo Profile page
import Card from '@/components/Card'
import CoreSelect from '@/components/CoreSelect'
import CoreButton from '@/components/CoreButton'
import { H } from 'hennig-common'
import CoreLabel from '@/components/CoreLabel'

export default {
  name: 'Profile',
  components: {
    CoreLabel,
    CoreButton,
    CoreSelect,
    Card
  },
  data () {
    return {
      formSaving: false,
      form: {
        name: '',
        cpf: ''
      },
      api: {
        expire: '',
        token: '',
        decrypt_key: ''
      },
      expire_items: [
        {
          value: '',
          text: 'Não expirar'
        },
        {
          value: '1 day',
          text: '1 dia'
        },
        {
          value: '15 days',
          text: '15 dias'
        },
        {
          value: '1 month',
          text: '1 mês'
        },
        {
          value: '1 year',
          text: '1 ano'
        }
      ],
      doc_link: process.env.VUE_APP_HOST
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      H.rpc('Profile', 'getData', [], r => {
        if (r) {
          for (const prop in this.form) {
            this.form[prop] = r[prop]
          }
        }
      })
    },
    save () {
      this.formSaving = true
      H.rpc('Profile', 'setData', [this.form], r => {
        this.formSaving = false
      })
    },
    token () {
      H.rpc('Profile', 'token', [this.api.expire, this.api.decrypt_key], r => {
        if (r) {
          this.api.token = r
        }
      })
    }
  }
}
</script>
