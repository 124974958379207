<template>
  <div class="card shadow rounded">
    <PageHeader
      v-if="title"
      :subtitle="subtitle"
      :title="title"
      :use-back-button="useBackButton"
      class="rounded-top"
    >
      <template #actions><slot name="header_buttons"></slot></template>
    </PageHeader>
    <div class="card-body">
      <span v-if="loading" class="text-muted">
        Aguarde...
      </span>
      <slot v-else></slot>
    </div>
    <slot name="buttons"></slot>
    <div v-if="buttons && buttons.length" class="card-footer bg-transparent text-end">
      <div
        v-for="(button, i) in buttons"
        :key="i"
        :class="button.class"
        class="d-inline-flex align-items-center"
        @click="button.action"
      >
        <i v-if="button.icon" :class="button.icon"></i>
        <span class="default-ml" v-text="button.label"></span>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  name: 'Card',
  components: { PageHeader },
  props: {
    loading: {},
    title: {
      String,
      default: ''
    },
    subtitle: {
      String,
      default: ''
    },
    buttons: {
      Array,
      default: () => []
    },
    useBackButton: {
      Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
  margin-bottom: 1em;
}

.card-footer > span:not(:first-child) {
  margin-left: 1em;
}
</style>
