<template>
  <div class="PageHeader card-header">
    <div v-if="useBackButton" class="flex-shrink-0 d-flex align-items-center link">
      <svg
        @click="onBackClick"
        width="8"
        height="32"
        fill="currentColor"
        class="bi bi-chevron-compact-left"
        viewBox="0 0 4 16"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          d="m 3.7371728,1.553 a 0.5,0.5 0 0 1 0.223,0.67 L 1.073173,8 3.9611728,13.776 a 0.5,0.5 0 1 1 -0.894,0.448 l -2.99999974,-6 a 0.5,0.5 0 0 1 0,-0.448 l 2.99999974,-6 a 0.5,0.5 0 0 1 0.67,-0.223 z"
        />
      </svg>
    </div>
    <div class="flex-grow-1">
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
    <div class="actions flex-shrink-0">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: String,
    subtitle: String,
    useBackButton: {
      Boolean,
      default: false
    }
  },
  methods: {
    onBackClick () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.PageHeader {
  padding: 1em;
  color: var(--white);
  background-color: var(--primary);
  display: flex;
  gap: 1rem;

  .title {
    text-transform: uppercase;
    color: var(--white);
  }

  .subtitle {
    font-size: .8rem;
    font-style: italic;
    color: var(--white);
  }

  .actions {
    display: flex;
    align-items: center;
    gap: .5rem;
  }
}
</style>
