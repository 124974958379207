<template>
  <button
    class="CoreButton btn"
    :class="[`btn-${type}`]"
    v-on="$listeners"
  >
    <template v-if="loading">
      <div class="spinner-border spinner-border-sm default-mr" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <span>{{ loadingText }}</span>
    </template>
    <template v-else>
      <slot>
        Continue
      </slot>
    </template>
  </button>
</template>
<script>
export default {
  name: 'CoreButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: 'Aguarde...'
    },
    type: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
<style lang="scss" scoped>
.CoreButton {
  height: 35px;
}
</style>
