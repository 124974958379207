<template>
  <div class="container-fluid">
    <Card title="Dispositivos" subtitle="Cadastramento de catracas, locker e outros servidores">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <EditHeader/>
            <DeleteHeader/>
            <StatusHeader/>
            <ContextMenuHeader/>
            <th
              data-column-id="online"
              data-width="36px"
              data-css-class="col-online"
              data-formatter="online"
              title="Comunicação"
            />
            <th data-column-id="place.name">Local</th>
            <th data-column-id="version" data-sortable="true">Versão</th>
            <th data-column-id="disk_use"  data-width="80px">Disco</th>
            <th data-column-id="load_avg" data-formatter="avg" data-width="80px">Load</th>
            <th data-column-id="db_online" data-formatter="bool" data-width="80px">Banco</th>
            <th data-column-id="db_primary" data-formatter="bool" data-width="80px">Primário</th>
            <th data-column-id="alerts" data-formatter="alerts" data-width="80px">Alertas</th>
            <th data-column-id="model" data-sortable="true" data-width="80px" data-formatter="model">Modelo</th>
            <th data-column-id="name" data-order="asc" data-sortable="true">Nome</th>
            <th data-column-id="ref" data-sortable="true">Integração</th>
            <th
              data-column-id="event"
              data-width="100px"
              data-css-class="col-event"
            >Status
            </th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import numeral from 'numeral'
import { H, initGrid } from 'hennig-common'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import PkHeader from '@/components/grid/PkHeader'
import Card from '@/components/Card'
import { listen, showInfo } from '@/notifications'
import ContextMenuHeader from '@/components/grid/ContextMenuHeader'

export default {
  name: 'Devices',
  components: {
    ContextMenuHeader,
    Card,
    PkHeader,
    StatusHeader,
    DeleteHeader,
    EditHeader
  },
  mounted () {
    this.init()
    listen('Kernel', 'Device', data => {
      const $tr = $(`tr[data-row-id="${data._id}"]`)
      $tr.find('.col-online').text(data.online)
      $tr.find('.col-event').text(data.event)
    })
  },
  methods: {
    init () {
      const that = this
      const addLabel = 'Novo dispositivo'
      const collectionObj = 'Device'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        addLabel,
        confirmationMethod: (cb) => {
          this.dialogs()
            .Confirmation
            .show({ text: 'Deseja realmente excluir esta dispositivo?' }, cb)
        },
        formatters: {
          model (column, row) {
            if (row[column.id] === 'camera') {
              return '<i class="la la-camera" title="Câmera"></i>'
            }

            if (row[column.id] === 'controlid') {
              return '<i class="la la-door-closed" title="Porta ControlID (Secbox)"></i>'
            }

            if (row[column.id] === 'controlid-door') {
              return '<i class="la la-door-closed" title="Porta ControlID"></i>'
            }

            if (row[column.id] === 'controlid-catraca') {
              return '<i class="la la-exchange-alt" title="Catraca ControlID"></i>'
            }

            if (row[column.id] === 'server') {
              return '<i class="la la-server" title="Servidor"></i>'
            }

            if (row[column.id] === 'mobile') {
              return '<i class="la la-mobile" title="Mobile"></i>'
            }

            // if (row[column.id]) {
            //   return `<i class="la la-check-circle" title="${row[column.id]}"></i>`
            // }

            return '<i class="la la-question"></i> ' + row[column.id]
          },
          avg (column, row) {
            if (!row[column.id]) return '-'
            const [data] = (row[column.id] + '').split(/,/)
            return numeral(parseFloat(data)).format('0.0')
          },
          alerts (column, row) {
            if (!row[column.id]) return '-'
            return '<i class="la la-info-circle text-danger"></i>'
          },
          online (column, row) {
            if (row[column.id] === 'Offline') {
              return `<i class="la la-times-circle text-danger" title="${row[column.id]}"></i>`
            }
            if (row[column.id]) {
              return `<i class="la la-check-circle text-success" title="${row[column.id]}"></i>`
            }

            return '<i class="la la-info-circle"></i>'
          },
          mnu (column, row) {
            let items = ''
            if (row.model && row.model.match(/^controlid/)) {
              items = `
                  <div class="dropdown-item">
                    <i class="la la-door-open command controlidOpen"></i>
                    <span class="command controlidOpen" data-row-id="${row._id}">Enviar comando abrir dispositivo</span>
                  </div>`
            } else {
              items = '<h6 class="dropdown-header">Nenhum comando para este dispositivo</h6>'
            }

            return `
              <div class="dropdown position-absolute">
                <i class="la la-ellipsis-v link" data-toggle="dropdown" aria-expanded="false"></i>
                <div class="dropdown-menu">
                  ${items}
                </div>
              </div>`
          }
        },
        customMethods: {
          controlidOpen (_id, $that, $grid, getRow) {
            const data = getRow()
            const c = data.server ? `Device@${btoa(data.server)}` : 'Device'
            // todo Review
            H.rpc(c, 'openControlID', [data.addr, 'admin', 'admin'], r => {
              if (!r) return
              showInfo('O comando para abrir a porta foi enviado')
            })
          }
        },
        actions: [
          // {
          //   icon: 'la-tools',
          //   name: 'controlid',
          //   handler (_id, $that, $grid, getRow) {
          //     const dialog = that.dialogs().ControlIdInit
          //     const row = getRow()
          //     dialog.show({
          //       addr: row.addr || ''
          //     }, data => {
          //       if (!data) return
          //       const c = row.server ? `Device@${btoa(row.server)}` : 'Device'
          //       H.rpc(c, 'initControlID', [data.addr, data.user, data.pass, data.host], r => {
          //         if (r) {
          //           dialog.hide()
          //         }
          //       })
          //     })
          //   }
          // },
          // {
          //   icon: 'la-certificate',
          //   name: 'cert',
          //   handler (_id, $that, $grid) {
          //     const dialog = that.dialogs().DeviceTokenDialog
          //     dialog.show(form => {
          //       H.rpc('Device', 'token', [_id, form.expire_at], token => {
          //         form.token = token
          //       })
          //     })
          //   }
          // }
          //,
          // {
          //   icon: 'la-upload',
          //   name: 'renew',
          //   handler (_id, $that, $grid) {
          //     H.rpc('Device', 'check', [], r => {
          //       console.log(r)
          //     })
          //   }
          // }
        ]
      })
        .on('loaded.rs.jquery.bootgrid', function (evnt) {
          $('.la-ellipsis-v.link').dropdown()
        })
    }
  }
}
</script>
