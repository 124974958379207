import { defineStore } from 'pinia'

export const useFilter = defineStore('filters', {
  persist: {
    storage: sessionStorage
  },
  state: () => ({
    event_id: '',
    event_name: ''
  }),
  actions: {
    setEvent (event) {
      this.event_id = event._id
      this.event_name = event.name
    }
  }
})
