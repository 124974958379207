import Vue from 'vue'
import { clearObj, H, setObj } from 'hennig-common'
import { closeError, listen, showError } from '@/notifications'
import { removeLoggedToken, formatNumber, getTokenData } from './common'
import moment from 'moment'
import { useWhiteLabel } from '@/stores/whitelabel'
import $ from 'jquery'
import { Events } from '@/events'

export async function getServersFromDNS () {
  if (process.env.VUE_APP_HOST !== 'DNS') {
    return
  }
  console.log('Reading DNS')
  let urls = sessionStorage.getItem('hosts') || ''
  if (urls) {
    return urls
  }

  const response = await fetch('https://dns.google/resolve?name=servers.acesso.center&type=TXT').then(r => r.json())
  urls = response && response.Answer && response.Answer[0].data
  urls = urls.split(/[;,]/)
    .sort(() => (Math.random() > 0.5) ? 1 : -1)
    .map(url => `https://${url}/`).join(',')
  sessionStorage.setItem('hosts', urls)
  return urls
}

export function setServerInvalid (prefix) {
  const servers = serversData()
  servers[prefix].status = moment().toJSON()
  window.sessionStorage.setItem('servers', JSON.stringify(servers))
}

// Todo Implementer um ping quando algo ficar fora
export function getServer () {
  const servers = serversData()
  for (const url in servers) {
    const diff = moment().diff(moment(servers[url].status), 'seconds')
    if (diff > 60) { // Depois de xx segundos tentamos este serve novamente
      closeError('not_avail')
      return url
    }
  }
  console.log('[TotalControl] No server available')
  const not_avail = '<p>Não há servidores disponíveis no momento.</p><a class="text-white link" href="javascript: sessionStorage.clear();location.reload();">Clique aqui para tentar novamente</a>'
  showError(not_avail, { id: 'not_avail', duration: -1 })
  return ''
}

function serversData () {
  const hosts = ((!process.env.VUE_APP_HOST || process.env.VUE_APP_HOST === 'DNS') ? sessionStorage.getItem('hosts') : process.env.VUE_APP_HOST) || ''
  const sessionServers = sessionStorage.getItem('servers')

  let servers = {}
  if (sessionServers) {
    servers = JSON.parse(sessionServers)
  } else {
    for (const url of hosts.split(',')) {
      servers[url] = {
        url,
        status: moment().subtract(1, 'hour').toJSON()
      }
    }
  }

  return servers
}

window.HDefaults = {
  headers () {
    return {
      token: window.localStorage.getItem('total_control_token') || '',
      'X-Brand': process.env.VUE_APP_BRAND
    }
  },
  beforeCallback (r, e) {
    const servers = serversData()
    if (e) {
      if (e.code === 503 || e.message === 'timeout' || e.message === '') {
        setServerInvalid(e.prefix)

        if (e.message === '') {
          showError('Servidor não respondeu. Tente novamente em alguns instantes.')
          return true
        }

        if (e.message === 'timeout') {
          showError('Servidor demorou para responder. Tente novamente em alguns instantes.')
          return true
        }
      } else if (e.code === 401) {
        removeLoggedToken()
        setTimeout(() => {
          location.href = '/'
        }, 500)
        return true
      }
    }
  },
  prefix: getServer,
  modalAdd: true,
  modalEdit: true,
  bootgridParams: () => ({
    identifier: '_id',
    rowCount: [100, 250, 500]
  }),
  formatters: {
    'number' (column, row) {
      if (!row[column.id]) {
        return '-'
      }

      return formatNumber(row[column.id])
    },
    empty (column, row) {
      if (row[column.id]) {
        return '-'
      }

      return '<i class="la la-check"></i>'
    },
    'bool' (column, row) {
      if (row[column.id]) {
        return '<i class="la la-check"></i>'
      }

      return '-'
    }
  },
  confirmationMethod: (cb) => {
    window.app.dialogs()
      .Confirmation
      .show({ text: 'Deseja realmente excluir?' }, cb)
  }
}
export const auth = Vue.observable({
  _id: '',
  name: '',
  email: '',
  user_level: 0
})

export const gates = Vue.observable({})

export const isAuthenticated = () => !!auth._id
export const isAdmin = () => auth.user_level >= 9
export const isManager = () => auth.user_level >= 8

const vm = new Vue()

function installNotificationListeners (user_id) {
  if (!user_id) {
    return
  }
  if (window.notificationListenersInstalled) {
    return
  }
  window.notificationListenersInstalled = true
  listen(`Ticketing.${user_id}`, 'Payment', data => {
    Events.$emit('Ticketing.Payment', data)
  })
  listen(`UI.${user_id}`, 'Progress', data => {
    Events.$emit('UI.Progress', data)
  })
}

export function authStatus () {
  return new Promise(resolve => {
    if (auth.loaded_at && auth.loaded_at.diff() > -30000) {
      return resolve(true)
    }
    console.log('Authetication')
    const store = useWhiteLabel()
    H.rpc('Auth', 'current', [], async r => {
      const data = await getTokenData(r)
      if (data) {
        setObj(vm, auth, data.user)
        setObj(vm, gates, data.gates)
        auth.loaded_at = moment()

        installNotificationListeners(data.user._id)
      } else {
        clearObj(vm, auth)
        store.$reset()
        clearObj(vm, gates)
        auth.loaded_at = null
      }
      resolve(true)
    })
    // Este será agrupado com o anterior
    H.rpc('Pub', 'modules', [], r => {
      if (r) {
        store.$patch({ whitelabel: r || {} })
      }
    })
  })
}
