<template>
  <div class="container">
    <div class="d-flex justify-content-center">
      <div class="card shadow">
        <div class="card-body">
          <h4>{{ message }}</h4>
          <div>
            <a href="/">Voltar para a home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  data () {
    return {
      message: ''
    }
  },
  mounted () {
    this.message = (this.$route.params && this.$route.params.message) || 'Ocorreu um erro inesperado'
  }
}
</script>

<style lang="scss" scoped>
.card {
    margin-top: 5rem;
    padding: 5rem;
    min-width: 600px;
}
</style>
