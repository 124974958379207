<template>
  <div class="container-xl">
    <Card title="Listas de acesso" :subtitle="subtitle">
      <FilterBar>
        <div v-if="event_visible" class="filter-box">
          <label>Evento</label>
          <EventSelect
            init-placeholder="Selecione um evento"
            v-model="search.event_id"
          />
        </div>
      </FilterBar>

      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <EditHeader/>
            <DeleteHeader/>
            <StatusHeader/>
            <th data-align="center" data-column-id="import" data-formatter="import" data-width="36px"></th>
            <th data-column-id="event_id" data-visible="false">Evento ID</th>
            <th v-if="event_visible" data-column-id="event.name">Evento</th>
            <th data-column-id="category_id" data-visible="false">Categoria ID</th>
            <th data-column-id="category.name">Categoria</th>
            <th data-column-id="name">Nome</th>
            <th data-column-id="col-progress" data-css-class="col-progress">Progresso</th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { H, initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import Card from '@/components/Card'
import { showInfo } from '@/notifications'
import { Events } from '@/events'
import FilterBar from '@/components/FilterBar.vue'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'

export default {
  name: 'AccessLists',
  components: {
    FilterBar,
    Card,
    StatusHeader,
    DeleteHeader,
    EditHeader,
    PkHeader
  },
  data () {
    return {
      event_visible: true,
      search: {
        event_id: ''
      }
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id', 'event_name']),
    subtitle () {
      if (this.event_visible) {
        return 'Listas que definem as regras de acesso'
      }

      return `Listas que definem as regras de acesso (${this.event_name})`
    }
  },
  async mounted () {
    // Navegacao veio de uma listagem que setou filtro em querystring
    if (this.$route.query.event_id) {
      this.search.event_id = this.$route.query.event_id
    } else {
      // Se tem um filtro setado no Pinia
      if (this.event_id) {
        this.search.event_id = this.event_id
        // Nao precisa mostrar a coluna
        this.event_visible = false
      }
    }
    Events.$on('UI.Progress', data => {
      const $tr = $(`tr[data-row-id="${data._id}"]`)
      $tr.find('.col-progress').text(data.value)
    })

    await this.$nextTick()
    this.init()
  },
  methods: {
    init () {
      const that = this
      const addLabel = 'Nova lista'
      const collectionObj = 'AccessList'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        modalAdd: true,
        modalEdit: true,
        addLabel,
        confirmationMethod: (cb) => {
          this.dialogs()
            .Confirmation
            .show({ text: 'Deseja realmente excluir esta lista?' }, cb)
        },
        actions: [
          {
            icon: 'la-upload',
            name: 'import',
            handler (_id, $that, $grid) {
              that.dialogs().Upload.show({}, content => {
                if (!content) return

                H.rpc('AccessList', 'import', [_id, content], r => {
                  if (r) {
                    // Background task
                    showInfo('Executando em segundo plano')
                  }
                })
              })
            }
          }
        ]
      })
    }
  }
}
</script>
