<template>
  <div class="container-fluid">
    <Card title="Logs de acesso negado">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <th data-column-id="event_id" data-visible="false">Evento ID</th>
            <th data-column-id="event.name">Evento</th>
            <th data-column-id="device_id" data-visible="false">Dispositivo ID</th>
            <th data-column-id="device.name">Dispositivo</th>
            <th data-column-id="error">Erro</th>
            <th data-column-id="message">Mensagem</th>
            <th data-column-id="code">Código</th>
            <th data-column-id="created_at" data-formatter="dt">Hora</th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import Card from '@/components/Card'

export default {
  name: 'DeniedLog',
  components: { Card, PkHeader },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const collectionObj = 'AccessDeniedLog'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        noAddButton: true
      })
    }
  }
}
</script>
