<template>
  <div class="input-group">
    <span class="input-group-text">
      <i class="las la-map-marker-alt"></i>
    </span>
    <template v-if="current">
      <input type="text" class="form-control" placeholder="Lat" v-model.number="current.coordinates[1]">
      <input type="text" class="form-control" placeholder="Lng" v-model.number="current.coordinates[0]">
    </template>
  </div>
</template>

<script>
import { BaseComponent } from 'hennig-common'

export default {
  name: 'GeoCoordsEditor',
  extends: BaseComponent,
  data () {
    return {
      options: [],
      current: {
        type: 'Point',
        coordinates: []
      }
    }
  },
  mounted () {
    this.baseMounted()
  },
  methods: {
    setValue (v) {
      if (!v) return
      this.current = v
    }
  }
}
</script>
