<template>
  <BaseModal
    ref="BaseModal"
    title="ControlID"
    v-if="visible"
  >
    <form onsubmit="return false" autocomplete="off">
      <div>
        <label>Endereço IP do dispositivo</label>
        <input v-model="form.addr" autocomplete="off" class="form-control" type="text"/>
      </div>
      <div>
        <label>Endereço IP do servidor de comunicacao do dispositivo (ip:porta)</label>
        <input v-model="form.host" autocomplete="off" class="form-control" type="text"/>
      </div>
      <div>
        <label>Usuário</label>
        <input v-model="form.user" autocomplete="off" class="form-control" type="text"/>
      </div>
      <div>
        <label>Senha</label>
        <input v-model="form.pass" autocomplete="off" class="form-control" type="text"/>
      </div>
    </form>
    <template v-slot:footer>
      <button
        class="btn btn-outline-primary"
        @click="openControlID()"
      >Enviar comando abrir
      </button>
      <button
        class="btn btn-outline-primary"
        @click="secondary()"
      >Cancelar
      </button>
      <button
        class="btn btn-primary"
        @click="primary()"
      >Inicializar
      </button>
    </template>
  </BaseModal>
</template>

<script>
import { BaseModal, H } from 'hennig-common'
import { showInfo } from '@/notifications'

export default {
  name: 'ControlIdInit',
  components: { BaseModal },
  data () {
    return {
      visible: false,
      form: {
        addr: '',
        host: 'acesso.center',
        user: 'admin',
        pass: 'admin'
      }
    }
  },
  methods: {
    show (data, callback) {
      this.form.addr = data.addr
      this.visible = true
      this.$nextTick(() => {
        this.$refs.BaseModal.show()
        this.callback = callback
      })
    },
    hide () {
      this.$refs.BaseModal.hide()
      this.visible = false
    },
    primary () {
      this.callback && this.callback(this.form)
    },
    secondary () {
      this.callback && this.callback(false)
    },
    /**
     * openControlID($addr, $user, $pwd, $host)
     */
    openControlID () {
      H.rpc('Device', 'openControlID', [this.form.addr, this.form.user, this.form.pass], r => {
        if (r) {
          showInfo('Comando foi enviado')
        }
      })
    }
  }
}
</script>
