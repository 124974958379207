<template>
  <div class="container-fluid">
    <Card title="Logs de acesso">
      <div class="d-flex justify-content-between gap">
        <div class="w-100">
          <EventSelect
            init-placeholder="Selecione um evento"
            v-model="search.event_id"
          />
        </div>
        <div class="w-100">
          <AccessListSelect
            :disabled="!search.event_id"
            :form-data="{ event_id: search.event_id }"
            init-placeholder="Selecione uma lista"
            v-model="search.accesslist_id"
          />
        </div>
        <div class="w-100">
          <PlaceSelect
            init-placeholder="Selecione um local"
            v-model="search.place_id"
          />
        </div>
        <div class="w-100">
          <select v-model="search.type" class="form-control">
            <option value="">- Todos tipos -</option>
            <option value="enter">Entradas</option>
            <option value="exit">Saídas</option>
            <option value="validated">Validação</option>
          </select>
        </div>
        <div class="flex-shrink-0">
          <div class="form-inline">
            <DateTimePicker
              v-model="search.created_at_i"
              :with-icon="false"
              :with-time="true"
              placeholder="Inicial"
            />

            <DateTimePicker
              v-model="search.created_at_f"
              :with-time="true"
              placeholder="Final"
            />
          </div>
        </div>
      </div>

      <div class="dropdown-divider"></div>

      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <th data-column-id="event_id" data-visible="false">Evento ID</th>
            <th data-column-id="event.name">Evento</th>
            <th data-column-id="device.place.name">Local</th>
            <th data-column-id="device_id" data-visible="false">Dispositivo ID</th>
            <th data-column-id="device.name">Dispositivo</th>
            <th data-column-id="type" data-formatter="type" data-width="100px">Tipo</th>
            <th data-column-id="code">Código</th>
            <th data-column-id="accesslist.id" data-visible="false">Lista ID</th>
            <th data-column-id="accesslist.name">Lista</th>
            <th data-column-id="object" data-formatter="object" data-width="100px">Obj</th>
            <th data-column-id="object_desc">Descrição</th>
            <th data-column-id="created_at" data-formatter="dt">Hora</th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import Card from '@/components/Card'
import DateTimePicker from '@/components/DateTimePicker'
import PlaceSelect from '@/components/PlaceSelect'

export default {
  name: 'AccessLog',
  components: { PlaceSelect, DateTimePicker, Card, PkHeader },
  data () {
    return {
      search: {
        event_id: '',
        accesslist_id: '',
        place_id: '',
        type: 'enter'
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const collectionObj = 'AccessLog'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        useExport: true,
        noAddButton: true,
        search: () => this.search,
        formatters: {
          object (column, row) {
            if (!row[column.id]) {
              return '-'
            }

            if (row[column.id].match(/Person/)) {
              return '<i class="la la-user"></i>'
            }

            if (row[column.id].match(/Access/)) {
              return '<i class="la la-id-card"></i>'
            }

            if (row[column.id].match(/Door/)) {
              return '<i class="la la-door-open"></i>'
            }

            return row[column.id]
          },
          type (column, row) {
            if (!row[column.id]) {
              return '-'
            }

            if (row[column.id] === 'enter') {
              return '<i class="la la-sign-in-alt"></i> <span>Entrada</span>'
            }

            if (row[column.id] === 'exit') {
              return '<i class="la la-sign-out-alt"></i> <span>Saída</span>'
            }

            if (row[column.id] === 'validated') {
              return '<i class="la la-dot-circle"></i> <span>Validação</span>'
            }

            if (row[column.id] === 'pending') {
              return '<i class="la la-hourglass-half"></i> <span>Aguardando</span>'
            }

            if (row[column.id] === 'door-button') {
              return '<i class="la la-dot-circle"></i> <span>Botão</span>'
            }

            if (row[column.id] === 'door-open') {
              return '<i class="la la-door-open"></i> <span>Abriu</span>'
            }

            if (row[column.id] === 'door-close') {
              return '<i class="la la-door-closed"></i> <span>Fechou</span>'
            }

            if (row[column.id] === 'timeout') {
              return '<i class="la la-hourglass"></i> <span>Desistência</span>'
            }

            return row[column.id]
          }
        }
      })
    }
  }
}
</script>
