import Vue from 'vue'
import Notifications from 'vue-notification'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './imports'
import './backend-calls'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

Vue.config.productionTip = false

Vue.use(Notifications)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

window.Popper = require('popper.js')

require('bootstrap')
require('bootstrap-select')
window.moment = require('moment')
require('moment/locale/pt-br')

window.jQuery = require('jquery')
window.datetimepicker = require('tempusdominus-bootstrap-4')

window.addEventListener('dragover', function (e) {
  e = e || event
  e.preventDefault()
}, false)

window.addEventListener('drop', function (e) {
  e = e || event
  e.preventDefault()
}, false)

Vue.mixin({
  methods: {
    /**
     * @returns {Dialogs}
     */
    dialogs () {
      return this.$root.$refs.app.$refs.Dialogs.$refs
    }
  }
})

window.app = new Vue({
  router,
  pinia,
  render: function (h) { return h(App, { ref: 'app' }) }
}).$mount('#app')
