<template>
  <div class="FilterBox">
    <label class="d-block">{{ label }}</label>
    <div class="items d-flex gap-sm">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterBox',
  props: {
    label: {}
  }
}
</script>

<style scoped lang="scss">
.FilterBox {
  flex: 1 1;
  max-width: 300px;

  label {
    height: 1em;
    line-height: 1em;
  }

  .items > * {
    flex: 1 1;
  }
}
</style>
