<template>
  <div v-if="data.customer" class="data">
    <label>Cliente</label>
    <div class="main">
      <h5>{{ data.customer.name }}</h5>
      <div class="flex-v-center">
        <label class="mr-3">Documento: </label><b>{{ data.customer.cpf }}</b>
      </div>
    </div>
    <div class="commands">
      <EditButton/>
    </div>
  </div>
</template>

<script>
import { formatNumber } from '@/common'
import EditButton from '@/components/EditButton'

export default {
  name: 'QueryRowCustomer',
  components: { EditButton },
  props: {
    data: {}
  },
  methods: {
    formatNumber
  }
}
</script>
