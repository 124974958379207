<template>
  <BaseModal
    ref="BaseModal"
    title="Upload"
  >
    <UploadSingleEditor
      :accept="accept"
      v-model="content"
    />

    <template v-slot:footer>
      <button class="btn btn-outline-primary" @click="secondary()">Cancelar</button>
      <button class="btn btn-primary" @click="primary()">Confirmar</button>
    </template>
  </BaseModal>
</template>

<script>

import { BaseModal } from 'hennig-common'
import UploadSingleEditor from '@/components/UploadSingleEditor'

export default {
  name: 'Upload',
  components: { UploadSingleEditor, BaseModal },
  data () {
    return {
      content: '',
      accept: 'image/*'
    }
  },
  methods: {
    show (props, callback) {
      this.accept = props.accept || 'image/*'
      this.$refs.BaseModal.show()
      this.callback = callback
    },
    primary () {
      this.callback && this.callback(this.content)
      this.$refs.BaseModal.hide()
    },
    secondary () {
      this.callback && this.callback(false)
      this.$refs.BaseModal.hide()
    }
  }
}
</script>
