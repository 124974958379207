<template>
  <QueryBase>
    <div class="card shadow">
      <div class="card-header">
        <div class="row justify-content-center">
          <div class="col-sm-6">
            <input class="form-control" v-model="search" @keyup.enter="load()"/>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="loading" class="text-center">Aguarde...</div>
        <div v-else-if="isEmpty" class="text-center text-muted">Nenhum registro encontrado</div>
        <template v-else>
          <QueryRowEvent :data="data"/>
          <QueryRowAccessList :data="data"/>
          <QueryRowProduct :data="data"/>
          <QueryRowCustomer :data="data"/>
        </template>
      </div>
    </div>
  </QueryBase>
</template>

<script>
import EditButton from '@/components/EditButton'
import { H } from 'hennig-common'
import moment from 'moment'
import numeral from 'numeral'
import QueryRowAccessList from '@/views/Query/QueryRowAccessList'
import QueryRowProduct from '@/views/Query/QueryRowProduct'
import QueryRowCustomer from '@/views/Query/QueryRowCustomer'
import QueryRowEvent from '@/views/Query/QueryRowEvent'
import QueryBase from '@/views/Query/QueryBase'
import { showForm } from '@/common'

export default {
  name: 'QueryAccess',
  components: {
    QueryBase,
    QueryRowEvent,
    QueryRowCustomer,
    QueryRowProduct,
    QueryRowAccessList
  },
  data () {
    return {
      loading: false,
      search: '',
      index: 0,
      rows: []
    }
  },
  computed: {
    data () {
      if (this.rows.length) {
        return this.rows[this.index]
      }

      return {}
    },
    isEmpty () {
      return !this.rows.length
    }
  },
  methods: {
    showForm () {
      showForm('Person', this.data._id, this.data, this.load)
    },
    load () {
      this.loading = true
      this.rows.splice(0)
      this.index = 0
      H.rpc('Query', 'access', [this.search], r => {
        this.loading = false
        if (!r) {
          return
        }

        for (const row of r) {
          this.rows.push(row)
        }
      })
    }
  }
}
</script>
