import numeral from 'numeral'
import jquery from 'jquery'
import { H } from 'hennig-common'
import moment from 'moment'
import jwt_decode from 'jwt-decode'

export function validExpiration (decoded) {
  if (!decoded) {
    return false
  }
  if (decoded.exp) {
    const expires_at = decoded.exp * 1000
    const now = moment().unix() * 1000
    if (expires_at < now) {
      return false
    }
  }

  return true
}

export function checkExpiration (decoded) {
  if (!validExpiration(decoded)) {
    throw new Error('Token expirado')
  }

  return true
}

export function getTokenData (token) {
  return new Promise((resolve, reject) => {
    token = token || localStorage.getItem('total_control_token') || ''
    if (!token) {
      resolve(false)
      return
    }

    const decoded = jwt_decode(token)
    if (validExpiration(decoded)) {
      setLoggedToken(token)
      resolve(decoded)
      return
    }

    resolve(false)
    removeLoggedToken()
  })
}

export function getEntryType (entry) {
  if (!entry.name) {
    return 'default'
  }

  if (entry.name.match(/(jpeg|bmp|png|gif|svg)$/i)) {
    return 'image'
  }

  if (entry.folder) {
    return 'folder'
  }

  if (entry.name.match(/txt$/i)) {
    return 'text'
  }

  if (entry.name.match(/(avi|mpg|mkv|mp4)$/i)) {
    return 'video'
  }

  if (entry.name.match(/(mp3|wav|ogg)$/i)) {
    return 'audio'
  }

  if (entry.name.match(/(doc|odt|docx)$/i)) {
    return 'doc'
  }

  if (entry.name.match(/(xls|ods|xlsx|csv)$/i)) {
    return 'xls'
  }

  if (entry.name.match(/pdf$/i)) {
    return 'pdf'
  }
  if (entry.name.match(/zip|rar|7z/i)) {
    return 'zip'
  }

  return 'default'
}

export function removeAccents (str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function clear (obj) {
  for (const prop of Object.getOwnPropertyNames(obj)) {
    delete obj[prop]
  }
}

export function empty () {
  //
}

export function startButtonState (vue) {
  const started = performance.now()
  vue.saveState = 'saving'
  return {
    started
  }
}

export function endButtonState (vue, o) {
  const update = () => {
    vue.saveState = 'saved'
    vue.changed = false
    setTimeout(() => {
      vue.saveState = ''
    }, 2000)
  }

  setTimeout(update, Math.max((performance.now() - o.started), 2000))
}

export function failButtonState (vue, o) {
  const update = () => {
    vue.saveState = 'failed'
    setTimeout(() => {
      vue.saveState = ''
    }, 2000)
  }

  setTimeout(update, Math.max((performance.now() - o.started), 1000))
}

export function renderFeedback (vue, e) {
  if (e.data) {
    for (const prop in e.data) {
      vue.$set(vue.feedbacks, prop, e.data[prop].join(','))
    }

    return true
  }
}

export function getFilesWebkitDataTransferItems (dataTransferItems) {
  function traverseFileTreePromise (item, path = '') {
    return new Promise(resolve => {
      if (!item) return
      if (item.isFile) {
        item.file(file => {
          file.filepath = path + file.name // save full path
          files.push(file)
          resolve(file)
        })
      } else if (item.isDirectory) {
        const dirReader = item.createReader()
        dirReader.readEntries(entries => {
          const entriesPromises = []
          for (const entr of entries) {
            entriesPromises.push(traverseFileTreePromise(entr, path + item.name + '/'))
          }
          resolve(Promise.all(entriesPromises))
        })
      }
    })
  }

  const files = []
  return new Promise((resolve, reject) => {
    const entriesPromises = []
    for (const it of dataTransferItems) {
      entriesPromises.push(traverseFileTreePromise(it.webkitGetAsEntry()))
    }
    Promise.all(entriesPromises)
      .then(entries => {
        // console.log(entries)
        resolve(files)
      })
  })
}

export function downloadURL (data, fileName) {
  const a = document.createElement('a')
  a.href = data
  a.download = fileName
  document.body.appendChild(a)
  a.style.display = 'none'
  a.click()
  a.remove()
}

export function downloadBlob (data, fileName, mimeType) {
  const blob = new Blob([data], {
    type: mimeType
  })
  const url = window.URL.createObjectURL(blob)
  downloadURL(url, fileName)
  setTimeout(() => window.URL.revokeObjectURL(url), 1000)
}

export function formatNumber (v) {
  if (!v) return '-'
  return numeral(v).format(',0.00')
}

export function formatDateTime (v) {
  if (!v) return '-'
  return moment(v).format('L LTS')
}

export function formatEnteredAt (v) {
  if (v) {
    return moment(v).format('L LTS')
  }

  return '- Não utilizado -'
}

export function getImageURL (item) {
  if (item.match(/^http/)) {
    return item
  }
  if (item.indexOf(',') > 0) {
    return item
  }
  return `data:image;base64,${item}`
}

export function showForm (c, _id, data, cb) {
  H.rpc(c, 'form', [_id], form => {
    Object.assign(form.data, data || {})
    H.createForm(form, {
      onstore (evnt, data, $form) {
        $form.closest('.modal').modal('hide')
        cb && cb()
      }
    })
  })
}

export function setLoggedToken (r) {
  window.localStorage.setItem('total_control_token', r)
}

export function removeLoggedToken () {
  window.localStorage.setItem('total_control_token', '')
  window.sessionStorage.clear()
}

/**
 * KwickPython
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export function localPrint (data) {
  return new Promise(resolve => {
    jquery
      .ajax({
        type: 'POST',
        async: true,
        url: 'http://127.0.0.1:9100/htbin/kp.py?p=Argox',
        dataType: 'json',
        timeout: 10000,
        data: { data }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        console.error(errorThrown)
        resolve(false)
      })
      .done(function (r) {
        console.info(r)
        resolve(true)
      })
  })
}

/**
 * Sleeps for a given amount of time
 *
 * @param {number} ms - number of milliseconds to sleep for
 * @returns {Promise}
 */
export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
