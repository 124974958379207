import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import AccessLists from '@/views/AccessLists'
import Logged from '@/headers/Logged'
import Home from '@/views/Home'
import { showError } from '@/notifications'
import Devices from '@/views/Devices'
import WhiteLabels from '@/views/WhiteLabels'
import Events from '@/views/Events'
import Access from '@/views/Access'
import AccessLog from '@/views/AccessLog'
import Users from '@/views/Users'
import Places from '@/views/Places'
import ErrorPage from '@/views/ErrorPage'
import Products from '@/views/Products'
import Areas from '@/views/Areas'
import ReportAccess from '@/views/ReportAccess'
import DeniedLog from '@/views/DeniedLog'
import Categories from '@/views/Categories'
import QueryAccess from '@/views/QueryAccess'
import Sectors from '@/views/Sectors'
import Profile from '@/views/Profile'
import Orders from '@/views/Orders'
import EventGroups from '@/views/EventGroups'
import ReportOrders from '@/views/ReportOrders'
import Permissions from '@/views/Permissions'
import ActivityLogs from '@/views/ActivityLogs'
import { authStatus, getServersFromDNS, isAdmin, isAuthenticated, isManager } from '@/globals'
import People from '@/views/People'
import UserGroups from '@/views/UserGroups'
import QueryPeople from '@/views/QueryPeople'
import TimeSpan from '@/views/TimeSpan'
import Webhooks from '@/views/Webhooks'
import EventsSmall from '@/views/EventsSmall.vue'
import EmailLog from '@/views/EmailLog.vue'

const Cash = () => import(/* webpackChunkName: "cash" */ '@/views/Cash')
const CashLog = () => import(/* webpackChunkName: "cash" */ '@/views/CashLog')
const CashSummary = () => import(/* webpackChunkName: "cash" */ '@/views/CashSummary')

const VisitorHome = () => import(/* webpackChunkName: "visitor" */ '@/views/Visitor/VisitorHome')
const VisitorSettings = () => import(/* webpackChunkName: "visitor" */ '@/views/Visitor/VisitorSettings')
const VisitorCards = () => import(/* webpackChunkName: "visitor" */ '@/views/Visitor/VisitorCards')
const VisitorPlaces = () => import(/* webpackChunkName: "visitor" */ '@/views/Visitor/VisitorPlaces')

const Dash = () => import(/* webpackChunkName: "dash" */ '@/views/Dash')
const DashManager = () => import(/* webpackChunkName: "dash" */ '@/views/DashManager')

const LandPage = () => import(/* webpackChunkName: "public" */ '@/views/Desktop/LandPage')
const Terms = () => import(/* webpackChunkName: "public" */ '@/views/Terms')

const authRequired = true
const adminRequired = true
const managerRequired = true
const publicAccess = true

const routes = [
  {
    path: '/login',
    name: 'Login',
    components: { default: Login }
  },
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
      header: Logged
    },
    meta: { authRequired }
  },
  { path: '/terms', components: { default: Terms }, meta: { publicAccess } },
  { path: '/error', name: 'Error', components: { default: ErrorPage }, meta: { publicAccess } },
  { path: '/desktop', components: { default: LandPage }, meta: { publicAccess } },
  { path: '/dash/manager', name: 'DashManager', components: { default: DashManager, header: Logged }, meta: { managerRequired, authRequired } },
  { path: '/dash/:dash_id?', name: 'Dash', components: { default: Dash, header: Logged }, meta: { authRequired } },
  { path: '/profile', components: { default: Profile, header: Logged }, meta: { authRequired } },
  { path: '/activity/log', components: { default: ActivityLogs, header: Logged }, meta: { authRequired } },
  { path: '/permissions', components: { default: Permissions, header: Logged }, meta: { authRequired } },
  { path: '/query/access', components: { default: QueryAccess, header: Logged }, meta: { authRequired } },
  { path: '/query/people', components: { default: QueryPeople, header: Logged }, meta: { authRequired } },
  { path: '/report/access', components: { default: ReportAccess, header: Logged }, meta: { authRequired } },
  { path: '/report/orders', components: { default: ReportOrders, header: Logged }, meta: { authRequired } },
  { path: '/whitelabels', components: { default: WhiteLabels, header: Logged }, meta: { adminRequired } },
  { path: '/cash', name: 'Cash', components: { default: Cash, header: Logged }, meta: { authRequired, title: 'Caixa' } },
  { path: '/cash/log', name: 'CashLog', components: { default: CashLog, header: Logged }, meta: { authRequired, title: 'Lançamentos de Caixa' } },
  { path: '/cash/summary', name: 'CashSummary', components: { default: CashSummary, header: Logged }, meta: { authRequired, title: 'Resumo de Caixa' } },
  { path: '/users', components: { default: Users, header: Logged }, meta: { authRequired } },
  { path: '/usergroups', components: { default: UserGroups, header: Logged }, meta: { authRequired } },
  { path: '/devices', components: { default: Devices, header: Logged }, meta: { authRequired, title: 'Dispositivos' } },
  { path: '/events', components: { default: Events, header: Logged }, meta: { authRequired, title: 'Eventos' } },
  { path: '/events/select', name: 'EventsSmall', components: { default: EventsSmall, header: Logged }, meta: { authRequired, title: 'Seleção Evento' } },
  { path: '/eventgroups', components: { default: EventGroups, header: Logged }, meta: { authRequired } },
  { path: '/areas', components: { default: Areas, header: Logged }, meta: { authRequired } },
  { path: '/places', components: { default: Places, header: Logged }, meta: { authRequired, title: 'Locais' } },
  { path: '/categories', components: { default: Categories, header: Logged }, meta: { authRequired } },
  { path: '/lists', components: { default: AccessLists, header: Logged }, meta: { authRequired } },
  { path: '/sectors', components: { default: Sectors, header: Logged }, meta: { authRequired } },
  { path: '/people', components: { default: People, header: Logged }, meta: { authRequired, title: 'Pessoas' } },
  { path: '/products', components: { default: Products, header: Logged }, meta: { authRequired } },
  { path: '/orders', components: { default: Orders, header: Logged }, meta: { authRequired } },
  { path: '/access', components: { default: Access, header: Logged }, meta: { authRequired } },
  { path: '/timespan', alias: '/horarios', components: { default: TimeSpan, header: Logged }, meta: { authRequired } },
  { path: '/access/logs', components: { default: AccessLog, header: Logged }, meta: { authRequired } },
  { path: '/email/logs', name: 'EmailLog', components: { default: EmailLog, header: Logged }, meta: { authRequired, title: 'Emails' } },
  { path: '/access/denied', components: { default: DeniedLog, header: Logged }, meta: { authRequired } },
  { path: '/settings/webhooks', components: { default: Webhooks, header: Logged }, meta: { authRequired } },
  { path: '/visitor/:person_id?', name: 'VisitorHome', components: { default: VisitorHome, header: Logged }, meta: { authRequired, title: 'Visitantes' } },
  { path: '/visitor/settings', name: 'VisitorSettings', components: { default: VisitorSettings, header: Logged }, meta: { authRequired, title: 'Visitantes - Configuração' } },
  { path: '/visitor/cards', name: 'VisitorCards', components: { default: VisitorCards, header: Logged }, meta: { authRequired, title: 'Visitantes - Cartões de acesso' } },
  { path: '/visitor/places', name: 'VisitorPlaces', components: { default: VisitorPlaces, header: Logged }, meta: { authRequired, title: 'Visitantes - Locais de destino' } },
  {
    path: '*',
    components: {
      default: ErrorPage
    }
  }
]

const router = new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = 'Total Control - ' + to.meta.title
  } else {
    document.title = 'Total Control'
  }

  await getServersFromDNS()

  if (to.matched.some(record => record.meta.publicAccess)) {
    return next()
  }

  await authStatus()

  if (window.g_route) {
    const to = window.g_route
    window.g_route = null
    next(to)
    return
  }

  if (to.matched.some(record => record.meta.managerRequired)) {
    if (isAdmin() || isManager()) {
      return next()
    }
    return next({ name: 'Error', params: { message: 'Para acessar este recurso é necessário uma permissão maior' } })
  }

  if (to.matched.some(record => record.meta.adminRequired)) {
    if (isAdmin()) {
      return next()
    }
    return next({ name: 'Error', params: { message: 'Para acessar este recurso é necessário ser um administrador' } })
  }

  if (to.matched.some(record => record.meta.authRequired)) {
    if (isAuthenticated()) {
      next()
    } else {
      next({ name: 'Login', params: { nextUrl: to.fullPath } })
    }
  } else {
    if (to.name === 'Login' && isAuthenticated()) {
      next({ name: 'Home' })
      return
    }

    next()
  }
})
Vue.use(VueRouter)
export default router
