<template>
  <div
    :class="{ over }"
    class="d-flex justify-content-start main p-2"
    @drop.prevent="drop($event)"
    @dragover.prevent="setOver()"
  >
    <div
      :style="getPreviewStyle()"
      class="img-thumbnail mr-2"
    >
      <div
        :style="imgItemStyle()"
        :class="imgItemClass()"
        class="img-item hover-blur"
      ></div>

      <i
        class="la la-trash link position-absolute"
        style="top: 4px; right: 4px; font-size: 1.5rem;"
        v-on:click="remove()"
      ></i>
    </div>
    <div style="overflow: hidden; position: relative; flex-grow: 1">
      <div class="d-flex align-items-center h-100">
        <i class="la la-2x la-plus"></i>
        <span class="label-text">{{ label }}</span>
      </div>
      <div v-if="file_name" class="file-info text-muted">{{ file_name }} ({{file_size}} bytes)</div>

      <input ref="inputFile" :accept="accept" type="file" v-on:change="upload"/>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import { BaseComponent } from 'hennig-common'
import { showError } from '@/notifications'
import { getFilesWebkitDataTransferItems } from '@/common'

export default {
  name: 'UploadSingleEditor',
  components: {},
  extends: BaseComponent,
  props: {
    accept: {
      String,
      default: 'image/*'
    },
    label: {
      String,
      default: 'Adicionar ...'
    }
  },
  data () {
    return {
      over: false,
      current: '',
      file_name: '',
      file_size: ''
    }
  },
  mounted () {
    this.baseMounted()
  },
  methods: {
    getPreviewStyle () {
      return {
        'min-width': '64px',
        height: '64px'
      }
    },
    imgItemClass () {
      if (!this.current) {
        return ''
      }

      if (this.current.match(/^data:video/)) {
        return 'video'
      }

      if (this.current.match(/^(http|data:image)/)) {
        return 'image'
      }

      if (this.accept === 'application/pdf') {
        return 'pdf'
      }

      return 'default'
    },
    imgItemStyle () {
      if (!this.current) {
        return {}
      }

      if (this.current.match(/^(http|data:image)/)) {
        return { '--image': `url(${this.current})` }
      }
    },
    remove () {
      $(this.$refs.inputFile).val('')
      this.current = ''
      this.file_name = ''
      this.file_size = ''
      this.$emit('input', this.current)
    },
    drop (ev) {
      ev.dataTransfer.dropEffect = 'copy'

      getFilesWebkitDataTransferItems(ev.dataTransfer.items).then(files => {
        this._upload(this.route, files)
      })
    },
    setOver () {
      this.over = true
      clearTimeout(this.overTimer)
      this.overTimer = setTimeout(() => {
        this.over = false
      }, 1000)
    },
    upload (evnt) {
      this._upload('/upload', evnt.target.files)
    },
    _upload (aUrl, aFiles) {
      if (aFiles.length === 0) {
        showError('Nenhum arquivo selecionado')
        return
      }

      for (const file of aFiles) {
        if (file.size > 50 * 10124 * 1024) {
          showError('Arquivo muito grande. Limite 50MB')
          return
        }

        const reader = new FileReader()
        reader.onloadend = () => {
          this.current = reader.result
          this.$emit('input', this.current)

          this.file_name = file.name
          this.file_size = file.size
        }

        reader.readAsDataURL(file)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  border: 1px solid #ccc;
}

.img-item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100%;
  height: 100%;

  &.default {
      background-image: url(~@/assets/011-file.png);
  }

  &.pdf {
      background-image: url(~@/assets/029-file.png);
  }

  &.video {
      background-image: url(~@/assets/050-file.png);
  }

  &.image {
      background-image: var(--image);
  }
}

.img-thumbnail {
  position: relative;

  .la-trash {
    opacity: .1;
    transition: opacity .5s;
  }

  &:hover {
    .hover-blur {
      filter: blur(6px);
    }

    .label, .la {
      opacity: 1;
    }
  }
}

.label {
  position: absolute;
  opacity: 0;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  top: 40px;
  left: 0;
  transition: opacity .5s;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.label-text {
  cursor: pointer;
}

.file-info {
  position: absolute;
  left: 0;
  bottom: 0;
  font-style: italic;
  white-space: nowrap;
  width: 100%;
}

input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: .01;
}
</style>
