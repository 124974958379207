<template>
  <nav class="navbar navbar-expand-md shadow-sm">
    <div class="container">
      <router-link class="navbar-brand d-flex flex-column align-items-center" to="/">
        <img class="brand-logo" :src="whitelabel.brand_logo" alt=""/>
      </router-link>

      <router-link class="navbar-brand d-flex flex-column align-items-center" to="/">
        {{ whitelabel.name }}
      </router-link>

      <button class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
        <i class="la la-2x la-bars"></i>
      </button>

      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <!-- Left Side Of Navbar -->
        <ul class="navbar-nav mr-auto flex-sm-row justify-content-sm-center">
          <router-link class="nav-link" :to="{name:'EventsSmall'}">{{ event_name || 'Selecionar evento' }}</router-link>
        </ul>

        <!-- Right Side Of Navbar -->
        <ul class="navbar-nav ml-auto flex-sm-row justify-content-sm-center">
          <li class="nav-item dropdown">
            <a id="navbarDropdown" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button">
              <i class="la la-bars"></i>
            </a>

            <div aria-labelledby="navbarDropdown" class="dropdown-menu dropdown-menu-right">

              <router-link
                v-if="isAdmin"
                class="dropdown-item"
                to="/whitelabels"
              >White Labels
              </router-link>

              <router-link v-if="isManager || gates.view_areas" class="dropdown-item d-flex align-items-center gap-sm" to="/areas">
                <i class="la la-map-marked"></i>
                <span>Areas</span>
              </router-link>
              <router-link v-if="isManager || gates.view_places" class="dropdown-item d-flex align-items-center gap-sm" to="/places">
                <i class="la la-map-signs"></i>
                <span>Locais</span>
              </router-link>

              <router-link v-if="isManager" class="dropdown-item d-flex align-items-center gap-sm" to="/devices">
                <i class="la la-shapes"></i>
                <span>Dispositivos</span>
              </router-link>

              <div class="dropdown-divider"></div>
              <router-link v-if="isManager" class="dropdown-item d-flex align-items-center gap-sm" to="/users">
                <i class="la la-user"></i>
                <span>Usuários</span>
              </router-link>

              <router-link v-if="isManager" class="dropdown-item d-flex align-items-center gap-sm" to="/usergroups">
                <i class="la la-users-cog"></i>
                <span>Grupos de Usuários</span>
              </router-link>
              <div class="dropdown-divider"></div>

              <router-link v-if="isManager || gates.view_people" class="dropdown-item d-flex align-items-center gap-sm" to="/people">
                <i class="la la-user"></i><span>Pessoas</span>
              </router-link>
              <router-link v-if="isManager || gates.view_people" class="dropdown-item d-flex align-items-center gap-sm" to="/facial">
                <i class="la la-photo-video"></i><span>Reconhecimento Facial</span>
              </router-link>

              <div class="dropdown-divider"></div>

              <router-link v-if="isManager || gates.view_events" class="dropdown-item" to="/events">Eventos</router-link>
              <router-link v-if="isManager && whitelabel.use_ticketing" class="dropdown-item" to="/eventgroups">Agrupadores de evento</router-link>
              <router-link v-if="isManager || gates.view_categories" class="dropdown-item" to="/categories">Categorias</router-link>
              <router-link v-if="isManager || gates.view_lists" class="dropdown-item" to="/lists">Listas</router-link>
              <router-link v-if="isManager || gates.view_access" class="dropdown-item" to="/access">Códigos</router-link>

              <template v-if="isManager && whitelabel.use_ticketing">
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item d-flex align-items-center gap-sm" to="/sectors">
                  <i class="la la-map-marker"></i>
                  <span>Setores</span>
                </router-link>
                <router-link class="dropdown-item d-flex align-items-center gap-sm" to="/products">
                  <i class="la la-gifts"></i>
                  <span>Produtos</span>
                </router-link>
                <router-link class="dropdown-item d-flex align-items-center gap-sm" to="/orders">
                  <i class="la la-shopping-cart"></i>
                  <span>Pedidos</span>
                </router-link>
                <router-link class="dropdown-item d-flex align-items-center gap-sm" :to="{name: 'Cash'}">
                  <i class="la la-money-bill-wave"></i>
                  <span>Caixa</span>
                </router-link>
              </template>

              <template v-if="isManager || gates.view_access_log">
                <div class="dropdown-divider"></div>
                <router-link  class="dropdown-item" to="/access/logs">Registros de acesso</router-link>
                <router-link v-if="isManager || gates.view_access_log" class="dropdown-item" to="/access/denied">Registros de acesso negado</router-link>
              </template>

              <template v-if="isManager">
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/settings/webhooks">
                  <i class="la la-gear mr-1"></i><span>Webhooks</span>
                </router-link>
              </template>

              <template v-if="whitelabel.use_visitor">
                <div class="dropdown-divider"></div>
                <router-link v-if="isManager || gates.use_visitor" class="dropdown-item" :to="{name: 'VisitorHome'}">
                  <i class="la la-external-link mr-1"></i><span>Visitantes - Home</span>
                </router-link>
                <router-link v-if="isManager" class="dropdown-item" :to="{name: 'VisitorSettings'}">
                  <i class="la la-gear mr-1"></i><span>Visitantes</span>
                </router-link>
              </template>

              <template v-if="isManager">
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item d-flex align-items-center gap-sm" to="/permissions">
                  <i class="la la-key"></i>
                  <span>Permissões</span>
                </router-link>
                <router-link class="dropdown-item d-flex align-items-center gap-sm" :to="{name: 'EmailLog'}">
                  <i class="la la-file-medical-alt"></i>
                  <span>Registros de envio de e-mail</span>
                </router-link>
                <router-link class="dropdown-item d-flex align-items-center gap-sm" to="/activity/log">
                  <i class="la la-heartbeat"></i>
                  <span>Registros de atividade</span>
                </router-link>
              </template>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a id="navbarDropdown2" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button">
              {{ auth.name }}
            </a>

            <div aria-labelledby="navbarDropdown2" class="dropdown-menu dropdown-menu-right">
              <router-link class="dropdown-item" to="/profile">Meus dados</router-link>
              <div class="dropdown-divider"></div>
              <div class="dropdown-item" @click="logout()">Sair</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Confirmation
      ref="Confirmation"
      text="Deseja realmente sair?"
    />
  </nav>
</template>

<script>
import moment from 'moment'
import { showInfo } from '@/notifications'
import Confirmation from '../dialogs/Confirmation'
import { auth, gates, isAdmin, isManager } from '@/globals'
import { removeLoggedToken } from '@/common'
import { mapState } from 'pinia'
import { useWhiteLabel } from '@/stores/whitelabel'
import { firebaseLogout } from '@/firebase'
import { useFilter } from '@/stores/filter'

export default {
  name: 'Logged',
  components: { Confirmation },
  data () {
    return {
      auth
    }
  },
  computed: {
    ...mapState(useWhiteLabel, ['whitelabel']),
    ...mapState(useFilter, ['event_name']),
    isAdmin,
    isManager,
    gates: () => gates
  },
  methods: {
    format (v) {
      return moment(v).fromNow()
    },
    showNotification () {
      if (!this.notifications.length) {
        showInfo('Nenhum novo aviso')
      }
    },
    logout () {
      this.dialogs().Confirmation.show({}, r => {
        if (r) {
          removeLoggedToken()
          firebaseLogout()
          location.href = '/'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.navbar {
  padding: 0;
  min-height: 50px;
  background-color: #fff;

  img.brand-logo {
    max-height: 50px;
  }
}

</style>
