import { defineStore } from 'pinia'
import { H } from 'hennig-common'
import Vue, { ref } from 'vue'
import { useFilter } from '@/stores/filter'

export const useDash = defineStore('dash', () => {
  const filters = useFilter()

  const orders_event = ref([])
  function ordersByEventAvgBar (event) {
    H.rpc('Dash', 'ordersByEventAvgBar', [{ event_id: filters.event_id }], (rows) => {
      if (!rows) return
      orders_event.value = rows
    })
  }

  const orders_event_progress = ref([])
  function ordersByEventProgress (event) {
    H.rpc('Dash', 'ordersByEventProgress', [{ event_id: filters.event_id }], (rows) => {
      if (!rows) return
      orders_event_progress.value = rows
    })
  }

  const ordersByEventTotalNumbersData = ref({
    regular: 0,
    half: 0
  })
  function ordersByEventTotalNumbers () {
    H.rpc('Dash', 'ordersEvent', [filters.event_id], r => (ordersByEventTotalNumbersData.value.regular = r))
    H.rpc('Dash', 'ordersEventHalf', [filters.event_id], r => (ordersByEventTotalNumbersData.value.half = r))
  }

  const loadAvailableDashboardsData = ref({
    loaded: false,
    rows: []
  })
  function loadAvailableDashboards () {
    if (loadAvailableDashboardsData.value.loaded) return
    H.rpc('DashManager', 'available', [], (r) => {
      loadAvailableDashboardsData.value.rows = r
    })
  }

  const loadDashboardComponentsData = ref({
    items: {}
  })
  function loadDashboardComponents (_id) {
    if (loadDashboardComponentsData.value.items[_id]) {
      return
    }
    H.rpc('DashManager', 'getComponents', [_id], (r) => {
      if (r) {
        Vue.set(loadDashboardComponentsData.value.items, _id, r)
        // loadDashboardComponentsData.value.items[_id] = r
      }
    })
  }

  return {
    orders_event,
    ordersByEventAvgBar,
    orders_event_progress,
    ordersByEventProgress,
    ordersByEventTotalNumbersData,
    ordersByEventTotalNumbers,
    loadAvailableDashboardsData,
    loadAvailableDashboards,
    loadDashboardComponentsData,
    loadDashboardComponents
  }
})
