<template>
  <div class="FilterBar">
    <div class="d-flex flex-wrap gap">
      <slot></slot>
    </div>
    <div class="dropdown-divider my-4"></div>
  </div>
</template>

<script>
export default {
  name: 'FilterBar'
}
</script>

<style lang="scss" scoped>
.FilterBar {
  div.filter-box {
    flex: 1 1;
    max-width: 300px;
  }
}
</style>
