<template>
  <div class="container-xl">
    <Card :title="title" :subtitle="subtitle" use-back-button="false">
      <FilterBar>
        <FilterBox label="Enviados entre">
          <DateTimePicker
            v-model="search.created_at_i"
            :with-time="true"
            :with-icon="false"
            placeholder="Inicial"
          />

          <DateTimePicker
            v-model="search.created_at_f"
            :with-time="true"
            :with-icon="false"
            placeholder="Final"
          />
        </FilterBox>
        <FilterBox label="Status">
          <select v-model="search.status" class="form-control custom-select">
            <option value="">- Selecione -</option>
            <option value="sent">Enviado</option>
            <option value="pending">Pendente</option>
            <option value="error">falhou</option>
          </select>
        </FilterBox>
      </FilterBar>

      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <DeleteHeader/>

            <th
              data-align="center"
              data-column-id="viewBody"
              data-formatter="viewBody"
              data-sortable="false"
              data-width="36px"
            ></th>

            <th data-column-id="to_email" data-sortable="true">E-mail</th>
            <th data-column-id="subject" data-sortable="true">Tipo</th>
            <th data-column-id="status" data-formatter="status">Status</th>
            <th data-column-id="created_at" data-formatter="dt" data-width="150px">Hora</th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { H, initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import Card from '@/components/Card'
import { Events } from '@/events'
import ContextMenuHeader from '@/components/grid/ContextMenuHeader.vue'
import { showInfo } from '@/notifications'
import FilterBar from '@/components/FilterBar.vue'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'
import UserSellerSelect from '@/components/UserSellerSelect.vue'
import FilterBox from '@/components/FilterBox.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'

export default {
  name: 'EmailLog',
  components: {
    DateTimePicker,
    FilterBox,
    FilterBar,
    Card,
    DeleteHeader,
    PkHeader
  },
  data () {
    return {
      search: {
        status: '',
        created_at_i: '',
        created_at_f: ''
      }
    }
  },
  computed: {
    title () {
      return 'E-mail'
    },
    subtitle () {
      return 'Registros de envio de e-mail'
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const that = this
      const collectionObj = 'Email'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        search: () => this.search,
        noAddButton: true,
        rowClick: true,
        actions: [
          {
            name: 'viewBody',
            icon: 'la-external-link-square',
            handler (_id) {
              H.rpc(collectionObj, 'view', [_id], function (r) {
                if (!r) {
                  return
                }
                H.createForm(r)
              })
            }
          }
        ],
        customMethods: {},
        formatters: {
          status (column, row) {
            const value = row[column.id]
            if (value === 'sent') {
              return 'Enviado'
            }

            if (value === 'pending') {
              return 'Pendente'
            }

            if (value === 'error') {
              return 'Falhou'
            }

            return row[column.id]
          }
        }
      })
    }
  }
}
</script>
