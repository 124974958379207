import Vue from 'vue'
import { io } from 'socket.io-client'
import { getServer } from '@/globals'

let socket

function getSocket () {
  console.log('Getting Websocket')
  return new Promise(resolve => {
    if (socket && socket.connected) {
      resolve(true)
      return
    }

    if (socket && !socket.connected) {
      socket.disconnect()
    }

    // Tira porta e ou slash
    const server = getServer()
    let url = server.replace(/(:\d+)?\/?$/, '')
    if (url.match(/127.0.0.1|localhost|local.test/)) {
      url = 'https://s01.acesso.center'
    }
    socket = io(url, {
      autoConnect: false,
      forceNew: true
    })

    socket
      .on('connect', () => {
        console.info('Connected')
        resolve(true)
      })
      .on('connect_error', () => {
        console.info('Couldn\'t connect')
        resolve(false)
      })
      .connect()
  })
}

export async function listen (channel, method, cb) {
  if (!await getSocket()) return
  socket.emit('public-join', channel, (res) => {
    if (res.error && res.error.message) {
      console.error(res.error.message)
    } else {
      socket.on(method, cb)
    }
  })
}

export async function leave (channel, method) {
  if (!await getSocket()) return
  socket.off(method)
  socket.emit('leave', channel)
}

export function closeError (id) {
  Vue.notify.close(id)
}

export function showInfo (text, options = {}) {
  Vue.notify({
    ignoreDuplicates: true,
    group: 'main',
    duration: 1000,
    text,
    type: 'success',
    ...options
  })
}

export function showError (text, options = {}) {
  Vue.notify({
    ignoreDuplicates: true,
    group: 'main',
    duration: 3000,
    text,
    type: 'error',
    ...options
  })
}
