<template>
  <BaseModal
    v-if="visible"
    ref="BaseModal"
    title="Senha"
  >
    <form onsubmit="return false">
      <input class="d-none" autocomplete="username" type="text">
      <div v-show="auth.user_level < 8">
        <label>Senha atual</label>
        <input v-model="form.current_password" autocomplete="current-password" class="form-control" type="password"/>
      </div>
      <div>
        <label>Nova senha</label>
        <input v-model="form.new_password" autocomplete="new-password" class="form-control" type="password"/>
      </div>
      <div>
        <label>Confirmação</label>
        <input v-model="form.confirm_password" autocomplete="new-password" class="form-control" type="password"/>
      </div>
    </form>
    <template v-slot:footer>
      <button
        class="btn btn-outline-primary"
        @click="secondary()"
      >Cancelar
      </button>
      <button
        class="btn btn-primary"
        @click="primary()"
      >Atualizar
      </button>
    </template>
  </BaseModal>
</template>

<script>

import { BaseModal } from 'hennig-common'
import { auth } from '@/globals'

export default {
  name: 'Password',
  components: { BaseModal },
  data () {
    return {
      visible: false,
      form: {
        current_password: '',
        new_password: '',
        confirm_password: ''
      }
    }
  },
  computed: {
    auth: () => auth
  },
  methods: {
    show (callback) {
      this.form.current_password = ''
      this.form.new_password = ''
      this.form.confirm_password = ''
      this.visible = true
      this.$nextTick(() => {
        this.$refs.BaseModal.show()
        this.callback = callback
      })
    },
    hide () {
      this.$refs.BaseModal.hide()
      this.visible = false
    },
    primary () {
      this.callback && this.callback(this.form)
    },
    secondary () {
      this.callback && this.callback(false)
    }
  }
}
</script>
