import emitter from 'tiny-emitter/instance'

module && module.hot && module.hot.dispose((data) => {
  // Clean up before reload
  for (const i in emitter.e) {
    delete emitter.e[i]
  }

  emitter.e = []
})

export const Events = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}
