<template>
  <div :class="getClass" class="CoreSelect">
    <CoreLabel
      :label="label"
      :label-tooltip="labelTooltip"
      :required="required"
    />
    <select
      :class="getClassInput"
      :disabled="disabled"
      :required="required"
      :value="value"
      class="form-control form-select"
      v-bind="$attrs"
      @blur="validate(value)"
      @input="fieldInput"
    >
      <option v-if="placeholder" value="">{{ getPlaceholder }}</option>
      <option v-for="item in items" :key="item[keyField]" :value="item[keyField]">{{ item[textField] }}</option>
    </select>
    <div class="invalid-feedback" v-html="customFeedback || feedback"></div>
  </div>
</template>

<script>
import { Tooltip } from 'bootstrap'
import CoreLabel from '@/components/CoreLabel'

export default {
  name: 'CoreSelect',
  components: { CoreLabel },
  inheritAttrs: false,
  props: {
    value: {},
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    customFeedback: {
      type: String,
      default: ''
    },
    labelTooltip: {
      type: String,
      default: ''
    },
    keyField: {
      String,
      default: 'value'
    },
    textField: {
      String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      wasValidated: false,
      feedback: ''
    }
  },
  computed: {
    getPlaceholder () {
      if (this.loading) {
        return ''
      }

      return this.placeholder
    },
    getClass () {
      return {
        'placeholder-glow': this.loading,
        disabled: this.disabled
      }
    },
    getClassInput () {
      const response = {
        placeholder: this.loading
      }
      if (this.rules && this.rules.length && this.wasValidated) {
        if (this.feedback || this.customFeedback) {
          response['is-invalid'] = true
        } else {
          response['is-valid'] = true
        }
      }

      return response
    }
  },
  watch: {
    /**
     * When the value is restored w/o user typing in field
     *
     * @param n
     * @param o
     */
    value (n, o) {
      if (n !== o) {
        this.validate(n)
        if (!n) this.wasValidated = false
      }
    }
  },
  methods: {
    fieldInput (e) {
      let v = e.target.value
      if (v === 'true') {
        v = true
      } else if (v === 'false') {
        v = false
      }
      this.validate(v)
      this.$emit('input', v)
      // Clean customFeedback requires .sync
      this.$emit('update:customFeedback', '')
    },
    emitIsValid () {
      this.$emit('isValid', this.wasValidated && !this.feedback)
    },
    validate (v) {
      this.wasValidated = true
      for (const rule of this.rules) {
        try {
          rule(v)
          this.feedback = ''
        } catch (e) {
          this.feedback = e.message
          break
        }
      }
      this.emitIsValid()
    }
  }
}
</script>

<style lang="scss" scoped>
.CoreSelect {
  &.disabled {
  }
}
</style>
