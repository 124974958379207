<template>
  <div class="container-xl">
    <Card title="Usuários">
      <FilterBar>
        <FilterBox label="Tipo de cadastro" class="mr-auto">
          <select v-model="search.source" class="form-control custom-select">
            <option value="system">Administração</option>
            <option value="visitors">Visitante (Todos)</option>
            <option value="file">Visitante (Importação)</option>
            <option value="register">Visitante (Cadastro)</option>
            <option value="firebase">Visitante (Google, Facebook)</option>
          </select>
        </FilterBox>
        <FilterBox label="" class="flex-grow-0 text-nowrap text-muted">
          {{ background_status }}
        </FilterBox>
        <FilterBox label="" class="flex-grow-0">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">Ações</button>
            <div class="dropdown-menu">
              <div class="dropdown-item" @click="importFile()"></div>
            </div>
          </div>
        </FilterBox>
      </FilterBar>

      <table class="table table-sm table-hover table-striped tableusers">
        <thead>
          <tr>
            <PkHeader/>
            <EditHeader/>
            <DeleteHeader/>
            <StatusHeader/>
            <th data-align="center" data-column-id="pwd" data-formatter="pwd" data-width="36px"></th>
            <th data-align="center" data-column-id="emailpwd" data-formatter="emailpwd" data-width="36px"></th>
            <th data-column-id="name" data-order="asc" data-sortable="true">Nome do usuário</th>
            <th data-column-id="email" data-sortable="true">E-mail do usuário</th>
            <th data-column-id="source_display_text" data-sortable="true">Tipo</th>
            <th data-column-id="created_at" data-formatter="dt" data-width="150px" data-sortable="true">Cadastro</th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { H, initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import Card from '@/components/Card'
import FilterBar from '@/components/FilterBar.vue'
import FilterBox from '@/components/FilterBox.vue'
import UserSellerSelect from '@/components/UserSellerSelect.vue'
import { showError, showInfo } from '@/notifications'
import { Events } from '@/events'

export default {
  name: 'Users',
  components: {
    FilterBox,
    FilterBar,
    Card,
    StatusHeader,
    DeleteHeader,
    EditHeader,
    PkHeader
  },
  data () {
    return {
      background_status: '',
      search: {
        source: 'system'
      }
    }
  },
  mounted () {
    this.init()

    Events.$on('UI.Progress', data => {
      if (data.object === 'ImportUserFile') {
        if (data.value === 'Done') {
          this.background_status = ''
          showInfo('Importação do arquivo foi finalizada<br>Clique para fechar...', { duration: -1 })
        } else {
          this.background_status = `Importou ${data.value} linhas`
        }
      }
    })
  },
  methods: {
    importFile () {
      const props = {
        accept: 'text/*'
      }
      this.dialogs().Upload.show(props, content => {
        if (!content) return

        H.rpc('User', 'importUserFile', [content], r => {
          if (r) {
            // Background task
            showInfo('A importação será executada em segundo plano')
          }
        })
      })
    },
    init () {
      const that = this
      const addLabel = 'Novo usuário'
      const collectionObj = 'User'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        addLabel,
        search: () => this.search,
        actions: [
          {
            icon: 'la-key',
            name: 'pwd',
            handler (_id, $that, $grid) {
              that.dialogs().Password.show(data => {
                if (!data) return
                H.rpc('User', 'updatePassword', [_id, data], r => {
                  if (!r) return
                  $grid.bootgrid('reload')
                  that.dialogs().Password.hide()
                })
              })
            }
          },
          {
            icon: 'la-envelope',
            name: 'emailpwd',
            handler (_id, $that, $grid) {
              that.dialogs().Confirmation.show({
                text: 'Uma nova senha será enviada para o e-mail cadastrado. Deseja continuar?'
              }, data => {
                if (!data) return
                H.rpc('User', 'emailPassword', [_id], r => {
                  if (!r) return
                  that.dialogs().Confirmation.hide()
                })
              })
            }
          }
        ]
      })
    }
  }
}
</script>
