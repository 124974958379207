<template>
  <div class="container-fluid">
    <Card title="Relatórios de Acesso">
      <div class="card-body">
        <div class="d-flex gap">
          <div class="flex-grow-1">
            <EventSelect
              init-placeholder="Selecione um evento"
              v-model="search.event_id"
              @input="load()"
            />
          </div>
          <div class="flex-grow-1">
            <AccessListSelect
              :disabled="!search.event_id"
              :form-data="{ event_id: search.event_id }"
              init-placeholder="Selecione uma lista"
              v-model="search.accesslist_id"
            />
          </div>
          <div class="flex-grow-1">
            <select
              v-model="search.aggregator"
              @input="load()"
              class="form-control custom-select"
            >
              <option value="">Selecione um totalizador</option>
              <option v-for="(a, name) in aggregators" :value="name" :key="name">{{ a.label }}</option>
            </select>
          </div>
          <div class="flex-shrink-0">
            <div class="form-inline">
              <DateTimePicker
                v-model="search.created_at_i"
                :with-icon="false"
                :with-time="true"
                placeholder="Inicial"
              />
              <DateTimePicker
                v-model="search.created_at_f"
                :with-time="true"
                placeholder="Final"
              />
            </div>
          </div>
          <div class="d-inline-flex align-items-end">
            <div @click="load" class="refresh btn btn-outline-primary">
              <i class="las la-sync"></i>
            </div>
          </div>
        </div>

        <div class="dropdown-divider"></div>

        <Pivot
          ref="Pivot"
          class="Pivot"
          available-fields-label-text="Agrupadores"
          no-data-warning-text="Nenhuma informação para exibir"
          rows-label-text="Linhas"
          cols-label-text="Colunas"
          :data="pivot.data"
          :fields="pivot.fields"
          :available-field-keys="pivot.availFieldKeys"
          :row-field-keys="pivot.rowFieldKeys"
          :col-field-keys="pivot.colFieldKeys"
          :is-data-loading="loading"
          :reducer="pivot.reducer"
        >
          <template slot="value" slot-scope="{ value }">
            {{ aggregators[search.aggregator].format(value) }}
          </template>
          <template slot="loading">
            <div class="text-center">
              Aguarde...
            </div>
          </template>
        </Pivot>
      </div>
    </Card>
  </div>
</template>

<script>
import { Pivot } from '@click2buy/vue-pivot-table'
import { H } from 'hennig-common'
import moment from 'moment'
import Card from '@/components/Card'
import DateTimePicker from '@/components/DateTimePicker'

export default {
  name: 'ReportAccess',
  components: { DateTimePicker, Card, Pivot },
  data () {
    return {
      loading: false,
      aggregators: {
        enter: { label: 'Entradas', format: (v) => v },
        exit: { label: 'Saídas', format: (v) => v }
      },
      search: {
        aggregator: 'enter',
        accesslist_id: '',
        created_at_i: '',
        created_at_f: '',
        event_id: ''
      },
      pivot: {
        data: [],
        fields: [
          {
            key: 'h',
            getter: item => moment(item.h).format('LT'),
            label: 'Hora'
          },
          {
            key: 'd',
            getter: item => moment(item.d).format('L'),
            label: 'Dia'
          },
          {
            key: 'm',
            getter: item => moment(item.m).format('LT'),
            label: 'Minuto'
          },
          {
            key: 'dev',
            getter: item => item.device_name,
            label: 'Dispositivo'
          },
          {
            key: 'place',
            getter: item => item.place_name,
            label: 'Local'
          },
          {
            key: 'cat',
            getter: item => item.category_name,
            label: 'Categoria'
          },
          {
            key: 'list',
            getter: item => item.accesslist_name,
            label: 'Lista'
          },
          {
            key: 'evnt',
            getter: item => item.event_name,
            label: 'Evento'
          }
        ],
        availFieldKeys: ['evnt', 'cat', 'list', 'dev', 'place', 'h', 'm'],
        rowFieldKeys: ['d'],
        colFieldKeys: [],
        reducer: (sum, item) => sum + item.v
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      H.rpc('Report', 'access', [this.search], rows => {
        this.pivot.data = Object.freeze(rows)
        this.$nextTick(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.refresh {
  min-width: 60px !important;
}

.Pivot {
  ::v-deep {
    th {
      font-size: 0.8rem;
      font-weight: 100;
      letter-spacing: -1px;
      padding: .25rem;
    }

    td {
      padding: .25rem;
      font-weight: 700;
      width: 1%;
    }

    > div:nth-child(1) {
      .left-col {
        display: none;
      }
    }
    > div:nth-child(2) {
      .left-col {
        display: none;
      }
    }
  }
}
</style>
