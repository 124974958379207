<template>
  <QueryBase>
    <Card title="Pesquisa de pessoas" subtitle="">
      <div class="d-flex justify-content-center">
        <div class="col-sm-3">
        </div>
        <div class="col-sm-6">
          <input class="form-control" v-model="search" @keyup.enter="load()"/>
        </div>
        <div class="col-sm-3">
          <template v-if="rows.length > 1">
            <div class="btn btn-primary btn-sm" @click="next">Ver próxima</div>
            <div class="small text-muted">Foram encontradas {{ rows.length }} pessoas</div>
          </template>
        </div>
      </div>

      <div class="dropdown-divider"></div>

      <div v-if="loading" class="text-center">Aguarde...</div>
      <div v-else-if="isEmpty" class="text-center text-muted">Nenhuma pessoa encontrada</div>
      <template v-else>
        <div v-if="data.name" class="data">
          <label>Nome</label>
          <div class="main">
            <h5>{{ data.name }}</h5>
            <!--            <div class="flex-v-center">-->
            <!--              <label class="mr-3">Última validação: </label><b>{{ formatEnteredAt(data.validated_at) }}</b>-->
            <!--            </div>-->
            <div class="flex-h gap">
              <div class="flex-v-center gap">
                <label>Última entrada:</label> <b>{{ formatEnteredAt(data.entered_at) }}</b>
              </div>
              <div class="flex-v-center" v-if="data.registration">
                <label>Nro registro:</label> <b>{{ data.registration }}</b>
              </div>
              <div class="flex-v-center" v-if="data.code">
                <label>Acesso:</label> <b>{{ data.code }}</b>
              </div>
            </div>
            <div class="flex-v-center">
              <label>Situação: </label>
              <b v-if="data.lock_id" class="text-danger">Bloqueado</b>
              <b v-else class="text-success">Ativo</b>
            </div>
          </div>
          <div class="commands">
            <EditButton @click="showForm(data)"/>
          </div>
        </div>
        <QueryRowEvent :data="data"/>
        <QueryRowAccessList :data="data"/>
      </template>
    </Card>
  </QueryBase>
</template>

<script>
import Card from '@/components/Card'
import QueryRowAccessList from '@/views/Query/QueryRowAccessList'
import QueryRowEvent from '@/views/Query/QueryRowEvent'
import { H } from 'hennig-common'
import EditButton from '@/components/EditButton'
import QueryBase from '@/views/Query/QueryBase'
import { formatEnteredAt, showForm } from '@/common'

export default {
  name: 'QueryPeople',
  components: {
    QueryBase,
    EditButton,
    QueryRowEvent,
    QueryRowAccessList,
    Card
  },
  data () {
    return {
      loading: false,
      search: '',
      index: 0,
      rows: []
    }
  },
  computed: {
    data () {
      if (this.rows.length) {
        return this.rows[this.index]
      }

      return {}
    },
    isEmpty () {
      return !this.rows.length
    }
  },
  methods: {
    formatEnteredAt,
    next () {
      if (this.index < this.rows.length - 1) {
        this.index++
      } else {
        this.index = 0
      }
    },
    showForm () {
      showForm('Person', this.data._id, this.data, this.load)
    },
    load () {
      this.loading = true
      this.rows.splice(0)
      this.index = 0
      H.rpc('Query', 'person', [this.search], r => {
        this.loading = false
        if (!r) {
          return
        }

        for (const row of r) {
          this.rows.push(row)
        }
      })
    }
  }
}
</script>
