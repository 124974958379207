<template>
  <BaseModal
    ref="BaseModal"
    title="Filtro por dia"
    v-if="visible"
    size="modal-sm"
  >
    <form onsubmit="return false" autocomplete="off">
      <div class="d-flex">
        <DateTimePickerInline v-model="form.date" :with-time="false" />
      </div>
    </form>
    <template v-slot:footer>
      <button
        class="btn btn-outline-primary"
        @click="secondary()"
      >Cancelar
      </button>
      <button
        class="btn btn-primary"
        @click="primary()"
      >Filtrar
      </button>
    </template>
  </BaseModal>
</template>

<script>
import { BaseModal } from 'hennig-common'
import moment from 'moment'
import DateTimePicker from '@/components/DateTimePicker'
import DateTimePickerInline from '@/components/DateTimePickerInline'

export default {
  name: 'DayDialog',
  components: { DateTimePickerInline, BaseModal },
  data () {
    return {
      visible: false,
      form: {
        date: ''
      }
    }
  },
  beforeMount () {
    this.form.date = moment().toJSON()
  },
  methods: {
    show (callback) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.BaseModal.show()
        this.callback = callback
      })
    },
    hide () {
      this.$refs.BaseModal.hide()
      this.visible = false
    },
    primary () {
      this.callback && this.callback(this.form)
      this.hide()
    },
    secondary () {
      this.callback && this.callback(false)
      this.hide()
    }
  }
}
</script>
