<template>
  <div class="container">
    <div class="card-deck">
      <Card title="Dashboards">
        <template #header_buttons>
          <router-link v-if="isAdmin || isManager" :to="{name: 'DashManager'}" class="m-0">
            <i class="la la-gear text-white link"></i>
          </router-link>
          <i class="las la-chart-line"></i>
        </template>
        <router-link
          v-for="dashboard of dashboards"
          :key="dashboard._id"
          :to="{name: 'Dash', params: {dash_id: dashboard._id}}"
          class="d-flex align-items-center justify-content-between"
        >
          <span>{{ dashboard.name }}</span>
          <i class="la la-angle-right"></i>
        </router-link>
      </Card>

      <Card title="Avisos">
        <template #header_buttons><i class="la la-bell"></i></template>
      </Card>
    </div>
  </div>
</template>

<script>
import { isAdmin, isManager } from '@/globals'
import { useWhiteLabel } from '@/stores/whitelabel'
import { mapActions, mapState } from 'pinia'
import { useDash } from '@/stores/dash'
import Card from '@/components/Card.vue'

export default {
  name: 'Home',
  components: { Card },
  computed: {
    ...mapState(useWhiteLabel, ['whitelabel']),
    // ...mapState(useDash, { dashboards: 'loadAvailableDashboardsData' }),
    ...mapState(useDash, ['loadAvailableDashboardsData']),
    dashboards () {
      return (this.loadAvailableDashboardsData && this.loadAvailableDashboardsData.rows) || []
    },
    isAdmin,
    isManager
  },
  mounted () {
    this.loadAvailableDashboards()
  },
  methods: {
    ...mapActions(useDash, ['loadAvailableDashboards'])
  }
}
</script>

<style lang="scss" scoped>
a {
  display: block;
  margin-bottom: .5em;
}
</style>
