<template>
  <VueSelect
    v-model="current"
    :filterable="false"
    :disabled="disabled"
    :options="options"
    :placeholder="placeholder"
    :reduce="row => row._id"
    label="name"
    @input="emitInput"
    @search="fetchOptions"
    @search:focus="fetchOptions"
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">
        Nenhuma lista encontrada: <em>{{ search }}</em>.
      </template>
      <em v-else style="opacity: 0.5;">Digite para procurar...</em>
    </template>
  </VueSelect>
</template>

<script>
import { VueSelect } from 'vue-select'
import { H } from 'hennig-common'

export default {
  name: 'AccessListSelect',
  components: { VueSelect },
  props: {
    value: {},
    disabled: {
      Boolean,
      default: false
    },
    formData: {
      default: null
    },
    initPlaceholder: {
      String,
      default: ''
    }
  },
  data () {
    return {
      current: '',
      placeholder: '',
      options: []
    }
  },
  watch: {
    value (v) {
      this.setValue(v)
    }
  },
  mounted () {
    this.placeholder = this.initPlaceholder
  },
  methods: {
    emitInput (v) {
      this.$emit('input', v)

      if (this.options.length) {
        for (const row of this.options) {
          if (row._id === v) {
            this.$emit('row', row)
            return
          }
        }
      }

      this.$emit('row', {})
    },
    getValue () {
      return this.current
    },
    setValue (v) {
      this.current = v
      this.fetchOptions(v || '', (loading) => {
        if (loading === false) {
          this.emitInput(v)
        }
      })
    },
    fetchOptions (search, loading) {
      search = search || this.current || ''
      loading && loading(true)
      let event_id = ''
      if (this.formData) {
        event_id = this.formData.event_id
      }
      if (this.$parent && this.$parent.getFormData) {
        event_id = this.$parent.getFormData().event_id
      }
      this.options = []
      H.rpc('AccessList', 'lookup', [search, { event_id }], r => {
        if (r) {
          this.options = r
        }

        loading && loading(false)
      })
    }
  }
}
</script>
