<template>
  <th
    data-align="center"
    data-column-id="active"
    data-formatter="checkbox"
    data-width="36px"
    title="Mudar status"
    data-header-no-icon="true"
  >...</th>
</template>

<script>
export default {
  name: 'StatusHeader'
}
</script>
