<template>
  <div class="container-xl">
    <Card title="Eventos" subtitle="Clique em um dos eventos para usar filtro global">
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <th data-column-id="name">Nome</th>
            <th data-column-id="access_ok" data-formatter="empty" data-width="80px" data-align="center">Acesso ativo</th>
            <th data-column-id="web_ok" data-formatter="empty" data-width="80px" data-align="center">Visivel WEB</th>
            <th data-column-id="pos_ok" data-formatter="empty" data-width="80px" data-align="center">Visivel POS</th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import Card from '@/components/Card'
import { showInfo } from '@/notifications'
import { mapActions } from 'pinia'
import { useFilter } from '@/stores/filter'

export default {
  name: 'EventsSmall',
  components: {
    Card,
    PkHeader
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions(useFilter, ['setEvent']),
    init () {
      const that = this
      const collectionObj = 'Event'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        modalAdd: false,
        modalEdit: false,
        rowClick (row) {
          that.setEvent(row)
          showInfo(row.name + ' foi selecionado')
        },
        formatters: {}
      })
    }
  }
}
</script>
