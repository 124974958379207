<template>
  <div
    :class="{ over }"
    class="main p-2"
    @drop.prevent="drop($event)"
    @dragover.prevent="setOver()"
  >
    <div
      :style="getPreviewStyle()"
      class="img-thumbnail"
    >
      <div v-if="capturing" class="overflow-hidden d-flex align-items-center justify-content-center">
        <canvas ref="canvas" id="canvas" class="d-none"></canvas>
        <video ref="webcam" id="webcam" autoplay playsinline width="320" height="240"></video>
      </div>

      <div
        v-else
        :style="imgItemStyle()"
        class="img-item hover-blur"
      ></div>

      <div class="toolbar">
        <i
          class="la la-camera link"
          v-on:click.stop="snap()"
        ></i>

        <i
          class="la la-cloud-upload link position-relative"
          v-on:click.stop="remove()"
        >
          <input ref="inputFile" :accept="accept" type="file" v-on:change="upload"/>
        </i>

        <i
          class="la la-trash link ml-auto"
          v-on:click.stop="remove()"
        ></i>
      </div>

    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import { BaseComponent } from 'hennig-common'
import { showError } from '@/notifications'
import { getFilesWebkitDataTransferItems } from '@/common'
const WebcamModule = () => import(/* webpackChunkName: "webcam" */ 'webcam-easy')

export default {
  name: 'ImageEditor',
  components: {},
  extends: BaseComponent,
  props: {
    value: {},
    accept: {
      String,
      default: 'image/*'
    }
  },
  data () {
    return {
      capturing: false,
      over: false,
      current: ''
    }
  },
  watch: {
    value (v) {
      this.setValue(v)
    }
  },
  mounted () {
    this.baseMounted()
    this.setValue(this.value)
  },
  methods: {
    async snap () {
      const Webcam = await WebcamModule()

      this.capturing = true
      await this.$nextTick()
      this.webcam = new Webcam(this.$refs.webcam, 'user', this.$refs.canvas)
      await this.webcam.start()
      setTimeout(() => {
        this.current = this.webcam.snap()
        this.$emit('input', this.current)
        this.webcam.stop()
        this.capturing = false
      }, 3000)
    },
    getPreviewStyle () {
      return {
        width: '256px',
        height: '256px'
      }
    },
    imgItemStyle () {
      if (!this.current) {
        return {}
      }

      if (this.accept === 'application/pdf') {
        return { backgroundImage: 'url(\'/img/029-file.svg\')' }
      }

      if (this.current.match(/^data/)) {
        return { backgroundImage: `url(${this.current})` }
      }

      if (this.current.match(/^http/)) {
        return { backgroundImage: `url(${this.current})` }
      }

      if (this.current.length > 512) { // Talvez um base64 sem o data:
        return { backgroundImage: `url(data:image;base64,${this.current})` }
      }

      return { backgroundImage: 'url(\'/img/011-file.svg\')' }
    },
    remove () {
      $(this.$refs.inputFile).val('')
      this.current = ''
      this.$emit('input', this.current)
    },
    drop (ev) {
      ev.dataTransfer.dropEffect = 'copy'

      getFilesWebkitDataTransferItems(ev.dataTransfer.items).then(files => {
        this._upload(this.route, files)
      })
    },
    setOver () {
      this.over = true
      clearTimeout(this.overTimer)
      this.overTimer = setTimeout(() => {
        this.over = false
      }, 1000)
    },
    upload (evnt) {
      this._upload('/upload', evnt.target.files)
    },
    _upload (aUrl, aFiles) {
      if (aFiles.length === 0) {
        showError('Nenhum arquivo selecionado')
        return
      }

      for (const file of aFiles) {
        if (file.size > 200 * 1024) {
          showError('Arquivo muito grande. Limite 200KB')
          return
        }

        const reader = new FileReader()
        reader.onloadend = () => {
          this.current = reader.result
          this.$emit('input', this.current)
        }

        reader.readAsDataURL(file)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.main {
  border: 1px solid #ccc;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.img-thumbnail {
  position: relative;

  .la {
    opacity: .1;
    transition: opacity .5s;
  }

  &:hover {
    .hover-blur {
      filter: blur(6px);
    }

    .label, .la {
      opacity: 1;
    }
  }
}

.label {
  position: absolute;
  opacity: 0;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  top: 40px;
  left: 0;
  transition: opacity .5s;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.label-text {
  cursor: pointer;
}

input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //border: 1px solid purple;
  opacity: .01;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  position: absolute;
  gap: 1rem;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  //border: 1px solid red;

  .la {
    font-size: 2rem;
  }
}
</style>
