<template>
  <div class="input-group justify-content-center">
    <div ref="input"></div>
  </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'

export default {
  name: 'DateTimePickerInline',
  props: {
    value: {},
    withTime: {
      Boolean,
      default: true
    }
  },
  data () {
    return {
      current_value: ''
    }
  },
  watch: {
    value (v) {
      if (moment(v).isValid()) {
        $(this.$refs.input).datetimepicker('date', moment(v).toDate())
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    setMaxDate (v) {
      if (typeof v === 'string') {
        v = moment(v).toDate()
      }

      $(this.$refs.input).datetimepicker('maxDate', v)
    },
    setMinDate (v) {
      if (typeof v === 'string') {
        v = moment(v).toDate()
      }

      $(this.$refs.input).datetimepicker('minDate', v)
    },
    init () {
      const opts = {
        locale: moment.locale(),
        icons: {
          time: 'la la-clock-o',
          date: 'la la-calendar',
          up: 'la la-arrow-up',
          down: 'la la-arrow-down',
          previous: 'la la-chevron-left',
          next: 'la la-chevron-right',
          today: 'la la-calendar-check-o',
          clear: 'la la-trash',
          close: 'la la-times'
        },
        buttons: {
          showClear: false,
          showClose: false
        },
        useCurrent: 'day',
        stepping: 10
      }

      $(this.$refs.input)
        .on('change.datetimepicker', evnt => {
          if (!evnt.date) {
            this.current_value = ''
          } else {
            this.current_value = evnt.date.format(this.withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')
          }
          this.$emit('input', this.current_value)
        })
        .datetimepicker({
          ...opts,
          format: this.withTime ? 'L LT' : 'L',
          inline: true
        })
    }
  }
}
</script>
