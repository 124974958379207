<template>
  <BaseModal
    ref="BaseModal"
    title="Acessos do pedido"
    v-if="visible"
  >
    <table ref='Table' class="table table-sm table-hover table-striped">
      <thead>
        <tr>
          <PkHeader/>
          <StatusHeader/>
          <th data-column-id="event_id" data-visible="false">Evento ID</th>
          <th data-column-id="event.name">Evento</th>
          <th data-column-id="accesslist_id" data-visible="false">Lista ID</th>
          <th data-column-id="accesslist.name">Lista</th>
          <th data-column-id="code">Código</th>
        </tr>
      </thead>
    </table>
  </BaseModal>
</template>

<script>
import $ from 'jquery'
import { BaseModal, initGrid } from 'hennig-common'
import StatusHeader from '@/components/grid/StatusHeader'
import PkHeader from '@/components/grid/PkHeader'

export default {
  name: 'OrderAccessDialog',
  components: {
    PkHeader,
    StatusHeader,
    BaseModal
  },
  data () {
    return {
      visible: false,
      form: {}
    }
  },
  mounted () {

  },
  methods: {
    load (filter) {
      const collectionObj = 'Access'
      initGrid({
        container: $(this.$refs.Table),
        collectionObj,
        noAddButton: true,
        search: () => filter,
        bootgridParams: () => ({
          navigation: 0
        })
      })
    },
    show (filter = {}, callback) {
      this.visible = true
      this.$nextTick(() => {
        this.load(filter)
        $(this.$refs.BaseModal.$el).one('hidden.bs.modal', () => {
          this.visible = false
          callback && callback()
        })
        this.$refs.BaseModal.show()
      })
    }
  }
}
</script>
