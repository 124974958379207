<template>
  <div class="FingerPrintsEditor">
    <div
      v-for="(img,index) in current"
      :key="`finger${index}`"
      class="img-thumbnail position-relative"
      style="width: 80px; height: 80px; display: inline-block; overflow: hidden; text-align: center">
      <img
        v-if="img.name"
        :src="getImageURL(img.content)"
        width="80"
        :title="img.name"
        :alt="img.name"
      />
      <img
        v-else
        :src="getImageURL(img)"
        width="80"
        alt=" "
      />
      <i
        class="la la-trash link position-absolute"
        style="top: 0; right: 0; font-size: 1.5rem; background-color: rgba(255, 255, 255, 0.5)"
        v-on:click="remove(index)"
      ></i>
    </div>
    <div class="add-button-container">
      <div class="d-flex justify-content-center align-items-center flex-column h-100">
        <i class="la la-2x la-plus"></i>
        <span class="label-text">Adicionar</span>
      </div>
      <input ref="uploader" multiple accept="image/*" type="file" v-on:change="getBase64"/>
    </div>
    <div class="add-button-container" @click="captureFromLocalService">
      <div class="d-flex justify-content-center align-items-center flex-column h-100">
        <i class="la la-2x la-fingerprint"></i>
        <span class="label-text">Capturar</span>
      </div>
    </div>
    <div class="dummy"></div>
    <div class="dummy"></div>
    <div class="dummy"></div>
    <div class="dummy"></div>
    <div class="dummy"></div>
    <div class="dummy"></div>
    <div class="dummy"></div>
    <div class="dummy"></div>
  </div>
</template>

<script>

import { BaseComponent } from 'hennig-common'
import { showError } from '@/notifications'
import { getImageURL } from '@/common'

export default {
  name: 'FingerPrintsEditor',
  extends: BaseComponent,
  data () {
    return {
      current: []
    }
  },
  mounted () {
    this.baseMounted()
  },
  methods: {
    async captureFromLocalService () {
      const response = await fetch('http://127.0.0.1:8081/capture').then(r => r.json()).catch(r => ({ error: 'Falha conexão' }))
      if (response.error) {
        showError(`<h5>Não foi possível capturar a biometria</h5><small>${response.error}</small>`)
        return
      }

      if (response.result) {
        this.current.push(response.result)
      }
    },
    getBase64 (evnt) {
      const aFiles = evnt.target.files
      for (const file of aFiles) {
        if (file.size > 200 * 1024) {
          showError('Arquivo muito grande. Limite 200KB')
          return
        }

        const reader = new FileReader()
        reader.onloadend = () => {
          this.current.push(reader.result)
          this.$emit('input', this.current)
          this.$refs.uploader.value = ''
        }

        reader.readAsDataURL(file)
      }
    },
    remove (index) {
      this.current.splice(index, 1)
    },
    setValue (v) {
      this.current = (v || []).filter(v => v)
    },
    getImageURL,
    getPreviewStyle () {
      return {
        'min-width': '80px',
        height: '80px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.FingerPrintsEditor {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: .5rem;

  .dummy {
    width: 80px;
  }
}
.add-button-container {
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid red;
  height: 100%;
  opacity: .01;
}
</style>
