<template>
  <div>
    <div class="w-100 mb-2">
      <div class="input-group">
        <select v-model="currentSelect" class="form-control custom-select">
          <option value="">- Selecione -</option>
          <option
            v-for="option in options"
            :value="option"
            v-text="option.name"
            :key="option.name"
          ></option>
        </select>
        <div class="input-group-append">
          <div class="input-group-text">
            <i class="la las la-chevron-circle-down" v-on:click="select(currentSelect)"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="list-group w-100 scroll-styled p-1 border">
      <div
        v-for="(option, index) in value"
        :key="index"
        class="list-group-item d-flex justify-content-between p-2"
      >
        <div class="d-flex justify-content-center align-items-center sequence">
          <div class="inline-edit">
            <input
              :value="index + 1"
              class="inline-edit fs-larger"
              size="2"
              @blur="updateIndex($event, option, index)"
            />
          </div>
        </div>
        <div class="flex-grow-1 d-flex flex-column">
          <div>{{ option.name }}</div>
          <div class="text-muted small" v-if="option && option.data && option.data.name">{{ option.data.name }}</div>
          <div class="text-muted small" v-else>Sem descrição</div>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center gap-sm">
          <div v-for="(fast, index) in option.fast" class="inline-edit" :key="'fast' + index">
            <div class="d-flex align-items-center gap-sm">
              <small>{{ fast.label }}</small>
              <input
                v-model="fast.value"
                :size="3"
              />
            </div>
          </div>

          <div class="d-flex flex-column justify-content-center">
            <i class="la las la-edit" v-on:click="showInfo(option, index)"></i>
          </div>

          <div class="d-flex flex-column justify-content-center">
            <i class="la las la-times-circle" v-on:click="unselect(option, index)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { H, setArr } from 'hennig-common'

export default {
  name: 'RulesEditor',
  data () {
    return {
      currentInfo: {},
      currentSelect: '',
      value: [],
      options: [],
      rpc_class: '',
      rpc_method: ''
    }
  },
  methods: {
    setProps (props) {
      for (const k in props) {
        this.$set(this, k, props[k])
      }
    },
    loadOptions (cb) {
      this.options = []
      // H.rpc('AccessList', 'rules', [], rows => {
      H.rpc(this.rpc_class, this.rpc_method, [], rows => {
        if (rows) {
          for (const row of rows) {
            this.options.push(row)
          }
        }

        cb && cb()
      })
    },
    updateIndex (evnt, option, index) {
      let newIndex = evnt.target.value - 1
      newIndex = Math.max(0, newIndex)
      newIndex = Math.min(newIndex, this.value.length)

      const [el] = this.value.splice(index, 1)
      this.value.splice(newIndex, 0, el)
    },
    showInfo (option, index) {
      H.rpc('AccessList', 'ruleForm', [option._id], form => {
        form.data = option.data || {}
        H.createForm(form, {
          onstore (evnt, data, $form) {
            option.data = data
            $form.closest('.modal').modal('hide')
          }
        })
      })
    },
    down (option, index) {
      const [el] = this.value.splice(index, 1)
      this.value.splice(Math.min(this.value.length, index + 1), 0, el)
    },
    up (option, index) {
      const [el] = this.value.splice(index, 1)
      this.value.splice(Math.max(0, index - 1), 0, el)
    },
    unselect (option, index) {
      this.value.splice(index, 1)
    },
    select (option) {
      const copy = { ...option }
      // this.$set(copy, 'selected', true)
      this.value.push(copy)
    },
    getValue () {
      return this.value
    },
    getOptionById (_id) {
      for (const option of this.options) {
        if (_id === option._id) {
          return option
        }
      }

      return null
    },
    setValue (v) {
      this.loadOptions(() => {
        this.value = []
        const values = v || []
        for (const value of values) {
          const option = this.getOptionById(value._id)
          if (!option) continue
          // option.selected = true
          this.value.push({ ...value })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.strikethrough {
  text-decoration: line-through;
}

.inline-edit {
  input {
    border: 1px solid rgba(12, 12, 12, 0.2);
    outline: none;
    text-align: right;
  }

  &:hover {
    input {
      border: 1px solid rgba(12, 12, 12, 0.4);
    }
  }
}

.mr25px {
  margin-right: 25px;
}

.btn-link {
  cursor: pointer;
}

.fs-larger {
  font-size: 1.1rem;
}

.sequence {
  span {
    font-size: 1.2rem;
  }

  img {
    width: 1.2rem;
    height: 1.2rem;
  }

  margin-right: .5em;
}

.la {
  font-size: 1.5em;
  cursor: pointer;
}

.list-group {
  height: 250px;
  background-color: #e9ecef;
}

.input-group-text {
  background-color: initial;
}

.scroll-styled {
  overflow-y: scroll;
}
</style>
