<template>
  <div class="TextAreaEditorVariables">
    <textarea
      class="form-control"
      ref="ta"
      v-model="current"
      :disabled="disabled"
      @input="emitInput"
      rows="10"
    />
    <TicketVariables @selected="addVar"/>
  </div>
</template>
<script>
import { BaseComponent } from 'hennig-common'
import TicketVariables from '@/components/TicketVariables.vue'

export default {
  name: 'TextAreaEditorVariables',
  components: {
    TicketVariables
  },
  extends: BaseComponent,
  props: {
    value: {},
    disabled: {
      Boolean,
      default: false
    }
  },
  data () {
    return {
      current: ''
    }
  },
  watch: {
    value (v) {
      this.current = v
    }
  },
  methods: {
    addVar (name) {
      const el = this.$refs.ta
      el.focus()
      const [start, end] = [el.selectionStart, el.selectionEnd]
      el.setRangeText(`{{${name}}}`, start, end, 'select')
      this.current = el.value
      this.emitInput(el.value)
    }
  }
}
</script>
