<template>
  <div class="container-fluid">
    <Card title="Acessos" :subtitle="subtitle">
      <FilterBar>
        <FilterBox v-if="event_visible" label="Evento">
          <EventSelect
            init-placeholder="Selecione um evento"
            v-model="search.event_id"
          />
        </FilterBox>
        <FilterBox label="Lista">
          <AccessListSelect
            :disabled="!search.event_id"
            :form-data="{ event_id: search.event_id }"
            init-placeholder="Selecione uma lista"
            v-model="search.accesslist_id"
          />
        </FilterBox>
        <FilterBox label="Nro sequencial">
          <CoreInput
            class="w-50"
            :model-value.sync="search.seq_event_i"
            placeholder="Inicial"
          />
          <CoreInput
            class="w-50"
            :model-value.sync="search.seq_event_f"
            placeholder="Final"
          />
        </FilterBox>
        <FilterBox label="">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">Comandos em lote</button>
            <div class="dropdown-menu">
              <div class="dropdown-item" @click="localPrintSelected()">Imprimir</div>
              <div class="dropdown-item" @click="lockSelected()">Bloquear</div>
              <div class="dropdown-item" @click="unlockSelected()">Liberar</div>
            </div>
          </div>
        </FilterBox>
      </FilterBar>

      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <PkHeader/>
            <EditHeader/>
            <DeleteHeader/>
            <StatusHeader />
            <ContextMenuHeader/>
            <th data-column-id="event_id" data-visible="false">Evento ID</th>
            <th v-if="event_visible" data-column-id="event.name">Evento</th>
            <th data-column-id="accesslist_id" data-visible="false">Lista ID</th>
            <th data-column-id="accesslist.name">Lista</th>
            <th data-column-id="code" data-css-class="text-monospace">Código</th>
            <th data-column-id="seq_event" data-css-class="text-monospace">Sequencial</th>
            <th data-column-id="type" data-formatter="type">Tipo</th>
            <th data-column-id="lock_id" data-formatter="lock_id">Situação</th>
            <th data-column-id="print_count">Impressões</th>
            <th data-column-id="created_at" data-formatter="dt" data-width="150px">Hora</th>
          </tr>
        </thead>
      </table>
    </Card>
  </div>
</template>

<script>
import $ from 'jquery'
import { H, initGrid } from 'hennig-common'
import PkHeader from '@/components/grid/PkHeader'
import EditHeader from '@/components/grid/EditHeader'
import DeleteHeader from '@/components/grid/DeleteHeader'
import StatusHeader from '@/components/grid/StatusHeader'
import { formatNumber, localPrint, sleep } from '@/common'
import Card from '@/components/Card'
import FilterBar from '@/components/FilterBar'
import CoreInput from '@/components/CoreInput.vue'
import { mapState } from 'pinia'
import { useFilter } from '@/stores/filter'
import FilterBox from '@/components/FilterBox.vue'
import ContextMenuHeader from '@/components/grid/ContextMenuHeader.vue'
import { showError, showInfo } from '@/notifications'

export default {
  name: 'Access',
  components: { ContextMenuHeader, FilterBox, CoreInput, FilterBar, Card, StatusHeader, DeleteHeader, EditHeader, PkHeader },
  data () {
    return {
      event_visible: true,
      search: {
        event_id: '',
        product_id: '',
        accesslist_id: '',
        seq_event_i: '',
        seq_event_f: ''
      }
    }
  },
  computed: {
    ...mapState(useFilter, ['event_id', 'event_name']),
    subtitle () {
      if (this.event_visible) {
        return 'Listagem de ingressos'
      }

      return `Listagem de ingressos (${this.event_name})`
    }
  },
  async mounted () {
    // Navegacao veio de uma listagem que setou filtro em querystring
    if (this.$route.query.event_id) {
      this.search.event_id = this.$route.query.event_id
    } else {
      // Se tem um filtro setado no Pinia
      if (this.event_id) {
        this.search.event_id = this.event_id
        // Nao precisa mostrar a coluna
        this.event_visible = false
      }
    }

    if (this.$route.query.product_id) {
      this.search.product_id = this.$route.query.product_id
    }

    await this.$nextTick()
    this.init()
  },
  methods: {
    selectedIds () {
      const selectedIds = $(this.$el).find('table').bootgrid('getSelectedRows')
      if (!selectedIds.length) {
        showError('Nenhum código foi selecionado na listagem')
        return null
      }
      return selectedIds
    },
    lockSelected () {
      this._updateSelected(false)
    },
    unlockSelected () {
      this._updateSelected(true)
    },
    _updateSelected (active) {
      const selectedIds = this.selectedIds()
      if (!selectedIds) {
        return
      }

      // Todos os request abaixo serao agrupados automaticamente
      let reload = false
      const cb = () => {
        if (!reload) {
          reload = true
          $(this.$el).find('table').bootgrid('reload')
        }
      }
      for (const _id of selectedIds) {
        H.rpc('Access', 'save', [{ _id, active }], cb)
      }
    },
    localPrintSelected () {
      const selectedIds = this.selectedIds()
      if (!selectedIds) {
        return
      }

      this.dialogs()
        .Confirmation
        .show({ text: `Confirma imprimir ${selectedIds.length} código(s)?` }, async r => {
          if (!r) return
          for (const _id of selectedIds) {
            if (!await this.localPrint(_id)) {
              break
            }
            await sleep(1000)
          }
        })
    },
    localPrint (_id) {
      return new Promise(resolve => {
        H.rpc('Access', 'generatePos', [_id], async base64 => {
          if (!base64) {
            resolve(false)
            return
          }

          if (typeof base64 === 'string') {
            base64 = [base64]
          }

          for (const i in base64) {
            const response = await localPrint(base64[i])
            if (!response) {
              showError('Impressão falhou')
              resolve(false)
              return
            }
          }

          showInfo('Impressão finalizada')
          resolve(true)
        })
      })
    },
    init () {
      const self = this
      const addLabel = 'Novo'
      const collectionObj = 'Access'
      initGrid({
        container: $(this.$el).find('table'),
        collectionObj,
        modalAdd: false,
        modalEdit: true,
        addLabel,
        bootgridParams: () => {
          return {
            selection: true,
            multiSelect: true
          }
        },
        search: () => this.search,
        confirmationMethod: (cb) => {
          this.dialogs()
            .Confirmation
            .show({ text: 'Deseja realmente excluir este código?' }, cb)
        },
        customMethods: {
          localPrint (_id) {
            self.localPrint(_id)
          }
        },
        formatters: {
          type (column, row) {
            if (row[column.id] === 'charge') {
              return 'Carga'
            }

            return 'Ingresso'
          },
          lock_id (column, row) {
            if (row[column.id]) {
              return 'Bloqueado'
            }

            return 'Ativo'
          },
          mnu (column, row) {
            const items = `
                  <div class="dropdown-item">
                    <i class="la la-print command localPrint"></i>
                    <span class="command localPrint" data-row-id="${row._id}">Imprimir localmente este ingresso</span>
                  </div>`

            return `
              <div class="dropdown position-absolute">
                <i class="la la-ellipsis-v link" data-toggle="dropdown" aria-expanded="false"></i>
                <div class="dropdown-menu">
                  ${items}
                </div>
              </div>`
          }
        }
      }).on('loaded.rs.jquery.bootgrid', function (evnt) {
        $('.la-ellipsis-v.link').dropdown()
      })
    }
  }
}
</script>
