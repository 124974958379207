<template>
  <BaseModal
    ref="BaseModal"
    title="Gerar token"
    v-if="visible"
  >
    <form onsubmit="return false" autocomplete="off">
      <div>
        <label>Expira em</label>
        <input v-model="form.expire_at" autocomplete="off" class="form-control" type="text"/>
      </div>
      <hr>
      <div>
        <label>Token</label>
        <textarea v-model="form.token" readonly class="form-control"></textarea>
      </div>
    </form>
    <template v-slot:footer>
      <button
        class="btn btn-outline-primary"
        @click="secondary()"
      >Cancelar
      </button>
      <button
        class="btn btn-primary"
        @click="primary()"
      >Gerar
      </button>
    </template>
  </BaseModal>
</template>

<script>
import { BaseModal } from 'hennig-common'

export default {
  name: 'DeviceTokenDialog',
  components: { BaseModal },
  data () {
    return {
      visible: false,
      form: {
        token: '',
        expire_at: '1 day'
      }
    }
  },
  methods: {
    show (callback) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.BaseModal.show()
        this.callback = callback
      })
    },
    hide () {
      this.$refs.BaseModal.hide()
      this.visible = false
    },
    primary () {
      this.callback && this.callback(this.form)
    },
    secondary () {
      this.callback && this.callback(false)
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
    word-break: break-all;
    font-family: monospace;
    min-height: 200px;
}
</style>
