<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-header d-flex justify-content-between">
        Relatórios de Venda
        <div @click="load" class="refresh btn btn-sm btn-outline-primary">
          <i class="las la-sync"></i>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4">
            <label>Evento</label>
            <EventSelect
              init-placeholder="Selecione um evento"
              v-model="search.event_id"
              @input="load()"
            />
          </div>
          <div class="col-sm-4">
            <label>Totalizar</label>
            <select
              v-model="aggregator"
              @input="load()"
              class="form-control custom-select"
            >
              <option v-for="(a, name) in aggregators" :value="name" :key="name">{{ a.label }}</option>
              <option value="qty">Quantidade</option>
              <option value="price">Total</option>
            </select>
          </div>
          <div class="col-sm-4">

          </div>
        </div>

        <div class="dropdown-divider"></div>

        <Pivot
          ref="Pivot"
          class="Pivot"
          available-fields-label-text="Agrupadores"
          no-data-warning-text="Nenhuma informação para exibir"
          rows-label-text="Linhas"
          cols-label-text="Colunas"
          :data="pivot.data"
          :fields="pivot.fields"
          :available-field-keys="pivot.availFieldKeys"
          :row-field-keys="pivot.rowFieldKeys"
          :col-field-keys="pivot.colFieldKeys"
          :is-data-loading="loading"
          :reducer="pivot.reducer"
        >
          <template slot="value" slot-scope="{ value }">
            {{ aggregators[aggregator].format(value) }}
          </template>
          <template slot="loading">
            <div class="text-center">
              Aguarde...
            </div>
          </template>
        </Pivot>
      </div>
    </div>
  </div>
</template>

<script>
import { Pivot } from '@click2buy/vue-pivot-table'
import { H } from 'hennig-common'
import moment from 'moment'
import numeral from 'numeral'

export default {
  name: 'ReportOrders',
  components: { Pivot },
  data () {
    return {
      loading: false,
      aggregator: 'qty',
      aggregators: {
        qty: { label: 'Quantidade', format: (v) => v },
        price: { label: 'Total', format: (v) => numeral(v).format(',0.00') }
      },
      search: {
        event_id: ''
      },
      pivot: {
        data: [],
        fields: [
          {
            key: 'hour',
            getter: item => moment(item.hour).format('LT'),
            label: 'Hora'
          },
          {
            key: 'day',
            getter: item => moment(item.day).format('L'),
            label: 'Dia'
          },
          {
            key: 'cat',
            getter: item => item.category_name,
            label: 'Categoria'
          },
          {
            key: 'list',
            getter: item => item.accesslist_name,
            label: 'Lista'
          },
          {
            key: 'evnt',
            getter: item => item.event_name,
            label: 'Evento'
          },
          {
            key: 'min',
            getter: item => moment(item.minute).format('LT'),
            label: 'Minuto'
          }
        ],
        availFieldKeys: ['cat', 'list', 'hour'],
        rowFieldKeys: ['evnt', 'day'],
        colFieldKeys: [],
        reducer: (sum, item) => sum + item[this.aggregator]
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      H.rpc('Report', 'orders', [this.search], rows => {
        this.pivot.data = Object.freeze(rows)
        this.$nextTick(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.refresh {
  min-width: 60px !important;
}

.Pivot {
  ::v-deep {
    > div:nth-child(1) {
      .left-col {
        display: none;
      }
    }
    > div:nth-child(2) {
      .left-col {
        display: none;
      }
    }
  }
}
</style>
