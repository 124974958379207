<template>
  <div class="EditButton" v-on="$listeners">
    <i class="la las la-edit"></i>
  </div>
</template>

<script>
export default {
  name: 'EditButton'
}
</script>

<style lang="scss" scoped>
.EditButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 22px;
  width: 22px;

  cursor: pointer;

  i {
    &:hover {
      color: var(--primary);
    }
  }
}
</style>
