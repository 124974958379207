<template>
  <BaseModal
    ref="BaseModal"
    title="Filtro por mês"
    v-if="visible"
    size="modal-sm"
  >
    <form onsubmit="return false" autocomplete="off">
      <div class="d-flex">
        <div class="w-50">
          <label>Ano</label>
          <input v-model="form.year" class="form-control" placeholder="Ex: 2022"/>
        </div>
        <div class="w-50">
          <label>Mês</label>
          <select class="form-control custom-select" v-model="form.month">
            <option value="01">Jan</option>
            <option value="02">Fev</option>
            <option value="03">Mar</option>
            <option value="04">Abr</option>
            <option value="05">Mai</option>
            <option value="06">Jun</option>
            <option value="07">Jul</option>
            <option value="08">Ago</option>
            <option value="09">Set</option>
            <option value="10">Out</option>
            <option value="11">Nov</option>
            <option value="12">Dez</option>
          </select>
        </div>
      </div>
    </form>
    <template v-slot:footer>
      <button
        class="btn btn-outline-primary"
        @click="secondary()"
      >Cancelar
      </button>
      <button
        class="btn btn-primary"
        @click="primary()"
      >Filtrar
      </button>
    </template>
  </BaseModal>
</template>

<script>
import { BaseModal } from 'hennig-common'
import moment from 'moment'

export default {
  name: 'MonthDialog',
  components: { BaseModal },
  data () {
    return {
      visible: false,
      form: {
        month: '',
        year: ''
      }
    }
  },
  beforeMount () {
    this.form.year = moment().year()
    this.form.month = moment().format('MM')
  },
  methods: {
    show (callback) {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.BaseModal.show()
        this.callback = callback
      })
    },
    hide () {
      this.$refs.BaseModal.hide()
      this.visible = false
    },
    primary () {
      this.callback && this.callback(this.form)
      this.hide()
    },
    secondary () {
      this.callback && this.callback(false)
      this.hide()
    }
  }
}
</script>
