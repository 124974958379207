<template>
  <BaseModal
    v-if="visible"
    ref="BaseModal"
    title="Confirmar"
  >
    <div v-html="text"></div>
    <template v-slot:footer>
      <button class="btn btn-outline-primary" @click="secondary()">{{ secondary_text }}</button>
      <button class="btn btn-primary" @click="primary()">{{ primary_text }}</button>
    </template>
  </BaseModal>
</template>

<script>

import { BaseModal } from 'hennig-common'

const def = {
  text: 'Deseja realmente continuar?',
  primary_text: 'Sim',
  secondary_text: 'Não'
}

export default {
  name: 'Confirmation',
  components: { BaseModal },
  data () {
    return {
      visible: false,
      text: 'Deseja realmente continuar?',
      primary_text: 'Sim',
      secondary_text: 'Não'
    }
  },
  methods: {
    show (props = {}, callback) {
      if (typeof props === 'function' && typeof callback === 'undefined') {
        callback = props
      }
      for (const prop in def) {
        this.$set(this, prop, def[prop])
      }
      for (const prop in props) {
        this.$set(this, prop, props[prop])
      }

      this.visible = true
      this.$nextTick(() => {
        this.$refs.BaseModal.show()
        this.callback = callback
      })
    },
    primary () {
      this.callback && this.callback(true)
      this.$refs.BaseModal.hide()
      this.visible = false
    },
    secondary () {
      this.callback && this.callback(false)
      this.$refs.BaseModal.hide()
      this.visible = false
    }
  }
}
</script>
