<template>
  <div v-if="data.accesslist" class="data">
    <label>Lista</label>
    <div class="main">
      <h5>{{ data.accesslist.name }}</h5>
      <div class="flex-v-center" v-if="data.accesslist.details">
        <label class="mr-3">Instruções: </label><b>{{ data.accesslist.details }}</b>
      </div>
    </div>
    <div class="commands">
      <!-- <EditButton/> -->
    </div>
  </div>
</template>
<script>
import EditButton from '@/components/EditButton'
import { formatEnteredAt } from '@/common'

export default {
  name: 'QueryRowAccessList',
  props: {
    data: {}
  },
  methods: {
    formatEnteredAt
  }
}
</script>
