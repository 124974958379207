import { defineStore } from 'pinia'
import { H } from 'hennig-common'

export const useWhiteLabel = defineStore('whitelabel', {
  persist: {
    storage: sessionStorage
  },
  state: () => ({
    whitelabel: {
      name: '',
      brand_logo: '',
      use_ticketing: false,
      use_access: false,
      use_visitor: false,
      use_security: false
    }
  }),
  actions: {}
})
